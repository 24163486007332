import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Subject, ignoreElements, merge, of, switchMap, tap } from 'rxjs';
import styled from 'styled-components';

import { useSelectGameVersionsForPlaytests } from 'app/src/selectors/game';
import { useSelectPlayerFitTestCredits } from 'app/src/selectors/session';
import { setPlayerFitTestCredits } from 'app/src/actions/session';
import { requestPlayerFitTest } from 'app/src/epics/playerfit';
import { uncaughtServerError } from 'app/src/actions/client';
import { getGameById } from 'app/src/epics/game';
import { registerModal } from 'app/src/modals';

import SelectInput from 'app/src/components/input/SelectInput';
import RadioInput from 'app/src/components/input/RadioInput';
import MultiSelectInput from 'app/src/components/input/MultiSelectInput';
import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';

import playtestAudiences from 'shared/utils/playtestAudiences';
import _ from 'shared/copy';

const Buttons = styled.div`
	padding-top: 20px;
	display: flex;
	justify-content: center;
`;

const Error = styled.div`
	color: ${props => props.theme.rose1};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;

	& + & {
		margin-top: 0;
	}
`;

const PlayerFitCredits = styled.div`
	font-size: 14px;
	margin-bottom: 16px;
`;

const DEVICE_MAP = {
	0: 'any',
	1: 'desktop',
	2: 'mobile',
};

const StartNewPlayerFitTestModal = ({ data: { gameId, gameApproved, onSuccessCallback } }) => {
	const dispatch = useDispatch();

	const versions = useSelectGameVersionsForPlaytests(gameId);
	const playerFitCredits = useSelectPlayerFitTestCredits();

	const initialVersion = versions[0]?.value || '';

	const [exit$] = useState(new Subject());
	const [playerFitTestStarted, setPlayerFitTestStarted] = useState(false);
	const [inspectorPassed, setInspectorPassed] = useState(versions[0]?.inspector_checklist?.['sdk-basics-gameplay-start'] === 'PASSED');

	const { handleSubmit, control, watch } = useForm({
		defaultValues: {
			version: initialVersion,
			device: 0,
			audience: [],
		},
	});

	const versionID = watch('version');

	useEffect(() => {
		const version = versions.find(({ value }) => value === versionID);

		setInspectorPassed(version?.inspector_checklist?.['sdk-basics-gameplay-start'] === 'PASSED');
	}, [versionID, versions]);

	const onSubmit = data => {
		const { version, device, audience = [] } = data;

		// Map audience to category ids
		const c = audience.reduce((acc, a) => {
			const { value } = playtestAudiences.find(({ desc }) => desc === a);
			return acc.concat(value);
		}, []);

		dispatch(requestPlayerFitTest.fetch({
			gameId,
			data: { version_id: version, game_id: gameId, categories: c.join(','), device_category: DEVICE_MAP[device] },
		}, ({ success$, error$ }) => merge(
			success$.pipe(
				tap(() => {
					onSuccessCallback();
					dispatch(setPlayerFitTestCredits({ gameId, credits: -1 }));
				}),
				ignoreElements(),
			),
			error$.pipe(
				switchMap(action => (
					of(
						uncaughtServerError({ action }),
						exit$.next(),
					)
				)),
			),
		)));

		setPlayerFitTestStarted(true);
	};

	useEffect(() => {
		// Always refresh game data when the modal is opened to get the latest Inspector status.
		dispatch(getGameById.fetch(gameId));
	}, []);

	// Refresh game data when the window is focused, this might be the dev
	// coming back from the Inspector after doing the checklist.
	useEffect(() => {
		const handleFocus = () => {
			dispatch(getGameById.fetch(gameId));
		};

		window.addEventListener('focus', handleFocus);

		return () => {
			window.removeEventListener('focus', handleFocus);
		};
	}, [dispatch, gameId]);

	const inspectorURL = `https://inspector.poki.dev/?game=poki-${versionID}`;

	return (
		<Modal
			exit$={exit$}
		>
			{playerFitTestStarted ? (
				<>
					<h2>Metrics test started</h2>
					<p>500 gameplays have been requested. We are looking for players. It might take a while for gameplays to appear here.</p>
					<Buttons>
						<Button onClick={() => exit$.next()}>
							{_`close`}
						</Button>
					</Buttons>
				</>
			) : (
				<form onSubmit={handleSubmit(onSubmit)}>
					<h2>Start new Player Fit Test</h2>
					<PlayerFitCredits>
						Remaining test for today: <strong>{playerFitCredits[gameId] ?? 2}</strong>
					</PlayerFitCredits>
					<Controller
						control={control}
						name="version"
						render={({ field: {	onChange, value } }) => (
							<SelectInput
								label="Game version"
								onChange={onChange}
								value={value}
								values={versions}
							/>
						)}
					/>
					{gameApproved && (
						<Controller
							control={control}
							name="device"
							render={({ field: { onChange, value } }) => (
								<RadioInput
									renderAsRow
									label="Player's device"
									valueSetter={onChange}
									value={value}
									values={[
										{ value: 0, key: 'any', desc: 'Any' },
										{ value: 1, key: 'desktop', desc: 'Desktop' },
										{ value: 2, key: 'mobile', desc: 'Mobile/Tablet' },
									]}
									disabled={false}
								/>
							)}
						/>
					)}
					<Controller
						control={control}
						name="audience"
						render={({ field: { onChange, value } }) => (
							<MultiSelectInput
								label="Audience (optional)"
								placeholder="- Select an audience -"
								valueSetter={onChange}
								value={value}
								values={playtestAudiences}
								maxSelection="4"
							/>
						)}
					/>
					{!inspectorPassed && (
						<Error>
							This version has not passed the step of the <a href={inspectorURL} target="_blank" rel="noopener noreferrer">Inspector</a> SDK Basics module:<br /><i>Is a 🎮 gameplayStart() event fired at the start of gameplay?</i> This is required to start a Player Fit Test.
						</Error>
					)}
					<Buttons>
						<Button submit disabled={!inspectorPassed || playerFitCredits[gameId] < 1}>Start Player fit test</Button>
					</Buttons>
				</form>
			)}
		</Modal>
	);
};

registerModal('start-new-playerfit-test', StartNewPlayerFitTestModal);

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ignoreElements, tap } from 'rxjs';
import styled from 'styled-components';

import parseAndDisplayOperatingSystem from 'app/src/utils/parseAndDisplayOperatingSystem';
import capitalizeFirstLetter from 'app/src/utils/capitalizeFirstLetter';
import getVersionLabel from 'app/src/utils/getVersionLabel';
import parseMessage from 'app/src/utils/parseMessage';
import formatTime from 'app/src/utils/formatTime';

import { translate } from 'app/src/epics/client';

import Table from 'app/src/components/ui/Table';
import Card from 'app/src/components/ui/Card';

import _ from 'shared/copy';

const DetailsTitle = styled.div`
	font-weight: bold;
	line-height: 20px;
	padding: 16px 16px 8px;
`;

const Details = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: 4px 16px;
	font-size: 14px;
`;

const TranslationTitle = styled.div`
	font-weight: bold;
	line-height: 20px;
	padding: 16px 16px 8px;
`;

const Translation = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: 4px 16px;
	font-size: 14px;
`;

const DetailColumn = styled.div`
	display: flex;
	flex-direction: column;
	padding-right: 40px;
`;

const DetailItem = styled.div`
	display: flex;
	line-height: 2.5em;
	white-space: nowrap;
`;

const DetailName = styled.div`
	font-weight: bold;
	padding-right: 12px;
`;

const DetailValue = styled.div`
`;

const ErrorsTitle = styled.div`
	font-weight: bold;
	line-height: 20px;
	padding: 16px 16px 8px;
	border-bottom: 1px solid ${props => props.theme.grey7};
`;

const ErrorName = styled.span`
	font-weight: bold;
`;

const ErrorMessage = styled.div`
	font: 12px/1.5em monospace;
`;

const GamePlayerFeedbackDetails = props => {
	const { item, included } = props;

	const dispatch = useDispatch();

	const [translation, setTranslation] = useState();
	const parsedErrors = JSON.parse(item.errors);

	const filteredErrors = parsedErrors?.filter(error => error.message !== 'Script error.') || [];

	useEffect(() => {
		if (item.english_message) {
			// If we already have an english message, then we don't need to translate.
			return;
		}

		dispatch(translate.fetch({ text: item.message }, ({ success$ }) => (
			success$.pipe(
				tap(({ payload: { result: { response } } }) => {
					const { results: [result] } = response;
					setTranslation(result);
				}),
				ignoreElements(),
			)
		)));
	}, [item.message, item.english_message]);

	let message;
	if (item.english_message) {
		// If we have an english message, then we show that in the table and
		// want to show the original in the expanded details.
		message = item.message;
	} else if (translation) {
		// If we didn't have an english message, but we have a translation, then
		// we show that.
		message = translation.translated;
	}

	return (
		<Card noPadding>
			<TranslationTitle>{_`translation`}</TranslationTitle>
			<Translation>
				{message ? <span dangerouslySetInnerHTML={{ __html: parseMessage(message, true, item.screenshot_url) }} /> : _`loading`}
			</Translation>
			<DetailsTitle>{_`details`}</DetailsTitle>
			<Details>
				<DetailColumn>
					<DetailItem>
						<DetailName>{_`device`}</DetailName>
						<DetailValue>{capitalizeFirstLetter(item.device_category || _`unknown`)}</DetailValue>
					</DetailItem>
					<DetailItem>
						<DetailName>{_`operatingSystem`}</DetailName>
						<DetailValue>{item.os_name ? parseAndDisplayOperatingSystem(item.os_name, item.os_version) : _`unknown`}</DetailValue>
					</DetailItem>
					<DetailItem>
						<DetailName>{_`browser`}</DetailName>
						<DetailValue>{item.browser_name || _`unknown`} {item.browser_version !== '0.0.0' ? item.browser_version : ''}</DetailValue>
					</DetailItem>
					<DetailItem>
						<DetailName>{_`loadingFinished`}</DetailName>
						<DetailValue>{item.loading_finished === null ? _`unknown` : item.loading_finished ? _`yes` : _`no`}</DetailValue>
					</DetailItem>
					<DetailItem>
						<DetailName>{_`timeOnPage`}</DetailName>
						<DetailValue>{item.gametime_seconds === null ? _`unknown` : formatTime(item.gametime_seconds)}</DetailValue>
					</DetailItem>
				</DetailColumn>
				<DetailColumn>
					<DetailItem>
						<DetailName>{_`versionPlayed`}</DetailName>
						<DetailValue>{item.p4d_version_id ? getVersionLabel(included.game_versions[item.p4d_version_id]?.attributes) : _`unknown`}</DetailValue>
					</DetailItem>
					<DetailItem>
						<DetailName>{_`adblockerDetected`}</DetailName>
						<DetailValue>{item.has_adblock === null ? _`unknown` : item.has_adblock ? _`yes` : _`no`}</DetailValue>
					</DetailItem>
					<DetailItem>
						<DetailName>{_`gameResolution`}</DetailName>
						<DetailValue>{item.game_resolution || _`unknown`}</DetailValue>
					</DetailItem>
					<DetailItem>
						<DetailName>{_`devicePixelRatio`}</DetailName>
						<DetailValue>{item.device_pixel_ratio || _`unknown`}</DetailValue>
					</DetailItem>
					<DetailItem>
						<DetailName>{_`webglRenderer`}</DetailName>
						<DetailValue>{item.webgl_renderer || _`unknown`}</DetailValue>
					</DetailItem>
				</DetailColumn>
			</Details>
			{parsedErrors && parsedErrors.length > 0 && (
				<>
					<ErrorsTitle>{_`errors`}</ErrorsTitle>
					<Table
						items={filteredErrors}
						compact
						scrollable
						noHeader
						columns={[
							{
								content: ({ item: error }) => (
									<ErrorMessage>
										{error.name ? (
											<ErrorName>{error.name}</ErrorName>
										) : null}
										{error.message ? `: ${error.message}` : null}
									</ErrorMessage>
								),
							},
						]}
					/>
				</>
			)}
		</Card>
	);
};

export default GamePlayerFeedbackDetails;

import lodash from 'lodash';
import { parseEmoji } from 'shared/copy';

const parseMessage = (message, showScreenshot, screenshotURL) => {
	const m = parseEmoji(lodash.escape(message));

	// Remove errornous null screenshots.
	if (m.endsWith('\n\nnull')) {
		return m.replace(/\n\nnull$/, '');
	}

	if (showScreenshot) {
		if (screenshotURL) {
			return `${m}\n\n<br><br><a href="${screenshotURL}" target="_blank"><img src="${screenshotURL}" height=150 /></a>`;
		} else {
			// Replace screenshot links with an image tag.
			// Support both the old screenshot urls like: https://poki-user-content.com/screenshots/2bafa2adef67146198dd323bb6d1a7be.png
			// and the new screenshot urls like: https://poki-user-content.com/screenshots/2b/2bafa2adef67146198dd323bb6d1a7be.png (fictional example)
			return m.replace(/\n\n(https?:\/\/poki-user-content.com\/screenshots\/([a-z0-9]{2}\/)?[a-z0-9]+\.png)$/g, '<br><br><a href="$1" target="_blank"><img src="$1" height=150 /></a>');
		}
	}

	// Remove the screenshot url at the end of the message.
	// Support both the old screenshot urls like: https://poki-user-content.com/screenshots/2bafa2adef67146198dd323bb6d1a7be.png
	// and the new screenshot urls like: https://poki-user-content.com/screenshots/2b/2bafa2adef67146198dd323bb6d1a7be.png (fictional example)
	return m.replace(/\n\n(https?:\/\/poki-user-content.com\/screenshots\/([a-z0-9]{2}\/)?[a-z0-9]+\.png)$/g, '');
};

export default parseMessage;

import { tap, ignoreElements } from 'rxjs';
import { InvalidParameterError } from 'app/src/errors';

import { createAuthorizedApiEpic, sortPageFilterQuery } from 'app/src/utils/api';
import getApiUrl from 'app/src/utils/getApiUrl';
import { pushEvent } from 'app/src/utils/tracking';
import dataFormatter from 'app/src/utils/dataFormatter';

export const listGamesByTeamId = createAuthorizedApiEpic(
	'game/list_games_by_team_id',
	(callApi, { teamId }) => callApi({
		url: getApiUrl('devs', `games?team_id=${teamId}&page[size]=1000`),
		method: 'GET',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
	}),
	null,
	{ allowParallel: true },
);

export const getGameById = createAuthorizedApiEpic(
	'game/get_game_by_id',
	(callApi, id) => callApi({
		url: getApiUrl('devs', `games/${id}`),
		method: 'GET',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
	}),
);

export const createGame = createAuthorizedApiEpic(
	'game/create_game',
	(callApi, { title, team_id, approved = false }) => callApi({
		url: getApiUrl('devs', 'games'),
		method: 'POST',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
		body: JSON.stringify(dataFormatter.serialize({
			stuff: {
				type: 'games',
				title,
				approved,
				team: {
					type: 'teams',
					id: team_id,
				},
				relationshipNames: ['team'],
			},
		})),
	}),
);

export const createGameAndVersion = createAuthorizedApiEpic(
	'game/create_game_and_version',
	(callApi, { formData }) => callApi({
		url: getApiUrl('devs', 'games/@create/versions'),
		method: 'POST',
		body: formData,
	}),
);

export const deleteGame = createAuthorizedApiEpic(
	'game/delete_game',
	(callApi, { gameId, data }) => callApi({
		url: getApiUrl('devs', `games/${gameId}`),
		method: 'DELETE',
		body: data,
	}),
);

export const patchGameVersion = createAuthorizedApiEpic(
	'game/patch_game_version',
	(callApi, { gameId, versionId, data }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/versions/${versionId}`),
		method: 'PATCH',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
		body: JSON.stringify(dataFormatter.serialize({
			stuff: {
				type: 'game_versions',
				...data,
			},
		})),
	}),
);

export const patchGame = createAuthorizedApiEpic(
	'game/patch_game',
	(callApi, { gameId, data }) => callApi({
		url: getApiUrl('devs', `games/${gameId}`),
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/vnd.api+json',
			Accept: 'application/vnd.api+json',
		},
		body: JSON.stringify(dataFormatter.serialize({
			stuff: {
				type: 'games',
				...data,
			},
		})),
	}),
);

export const patchGameAnnotations = createAuthorizedApiEpic(
	'game/patch_game_annotations',
	(callApi, { gameId, data }) => callApi({
		url: getApiUrl('devs', `games/${gameId}`),
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/vnd.api+json',
			Accept: 'application/vnd.api+json',
		},
		body: JSON.stringify(dataFormatter.serialize({
			stuff: {
				type: 'games',
				...data,
			},
		})),
	}),
);

export const patchGameExternalDomain = createAuthorizedApiEpic(
	'game/patch_game_external_domain',
	(callApi, { gameId, data }) => callApi({
		url: getApiUrl('devs', `games/${gameId}`),
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/vnd.api+json',
			Accept: 'application/vnd.api+json',
		},
		body: JSON.stringify(dataFormatter.serialize({
			stuff: {
				type: 'games',
				...data,
			},
		})),
	}),
);

export const gameSetVersionActive = createAuthorizedApiEpic(
	'game/set_version_active',
	(callApi, { gameId, versionId }) => callApi({
		url: getApiUrl('devs', `games/${gameId}`),
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/vnd.api+json',
			Accept: 'application/vnd.api+json',
		},
		body: JSON.stringify(dataFormatter.serialize({
			stuff: {
				type: 'games',
				tracks: [
					{
						track: 'public',
						version_id: versionId,
						weight: 100,
					},
				],
			},
		})),
	}),
);

export const createGameVersion = createAuthorizedApiEpic(
	'game/create_game_version',
	(callApi, { gameId, formData }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/versions`),
		method: 'POST',
		body: formData,
	}),
	({ success$ }) => (
		success$.pipe(
			tap(({ payload: { result: { response } } }) => {
				pushEvent('gameVersions', 'addZipFile', {
					gameId: response.game_id,
				});
			}),
			ignoreElements(),
		)
	),
);

export const syncGame = createAuthorizedApiEpic(
	'game/sync',
	(callApi, { gameId }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/_manualsync`),
		method: 'POST',
	}),
);

export const getGameErrorsPerMinute = createAuthorizedApiEpic(
	'game/get_errors_per_minute',
	(callApi, { versionIds }) => callApi({
		url: getApiUrl('realtime', 'metrics/errors'),
		method: 'POST',
		body: JSON.stringify(versionIds.sort()),
	}),
);

export const getRealTimePlayers = createAuthorizedApiEpic(
	'game/get_real_time_players',
	(callApi, { gameId }) => callApi({
		url: getApiUrl('realtime', `metrics/playground/${gameId}`),
		method: 'GET',
	}),
);

export const getGameAllowList = createAuthorizedApiEpic(
	'game/get_allowlist',
	(callApi, { gameId }) => (
		callApi({
			url: getApiUrl('devs', `games/${gameId}/_whitelist`),
			method: 'GET',
		})
	),
);

export const patchGameAllowList = createAuthorizedApiEpic(
	'game/patch_allowList',
	(callApi, { gameId, type, allowMobile, domainCollections }) => (
		callApi({
			url: getApiUrl('devs', `games/${gameId}/_whitelist`),
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			body: {
				type,
				allow_mobile: type === 'everywhere' ? allowMobile : type === 'poki' ? true : undefined,
				domain_collections: (
					type === 'custom'
						? domainCollections.filter(dc => dc.name !== 'poki').map(
							dc => ({
								name: dc.name,
								allow_mobile: dc.allowMobile,
								hide_footer: dc.hideFooter,
							}),
						)
						: undefined
				),
			},
		})
	),
);

export const downloadZip = createAuthorizedApiEpic(
	'game/download_zip',
	(callApi, { gameId, versionId, type = 'source' }) => {
		if (type !== 'source' && type !== 'hosted') throw new InvalidParameterError(`Invalid type given to game.downloadZip: ${type}`);

		return callApi({
			url: getApiUrl('devs', `games/${gameId}/download/${versionId}/${type}`),
			headers: { 'Content-Type': 'application/json' },
		});
	},
	({ success$ }) => (
		success$.pipe(
			tap(({ payload: { result: { response } } }) => {
				const { location } = response;
				window.open(getApiUrl('devs', location));
			}),
			ignoreElements(),
		)
	),
);

export const getGameEngineAnnotations = createAuthorizedApiEpic(
	'game/get_annotations_engine',
	callApi => (
		callApi({
			url: getApiUrl('devs', '_annotations/engine'),
			headers: { 'Content-Type': 'application/json' },
		})
	),
);

export const listAllVersionsByGameId = createAuthorizedApiEpic(
	'game/list_all_versions_by_game_id',
	(callApi, { gameId }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/versions?${sortPageFilterQuery({ sortField: 'created_at', direction: -1, perPage: 1000000000 })}`),
		method: 'GET',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);

export const listActiveVersionsByGameId = createAuthorizedApiEpic(
	'game/list_active_versions_by_game_id',
	(callApi, { gameId, sortField, sortDirection, page = 1, perPage = 1000000000 }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/versions?${sortPageFilterQuery({ sortField, sortDirection, page, perPage, filters: { archived_at: 'null', track: 'public' } })}`),
		method: 'GET',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);

export const listNonArchivedVersionsByGameId = createAuthorizedApiEpic(
	'game/list_non_archived_versions_by_game_id',
	(callApi, { gameId, sortField, sortDirection, page = 1, perPage = 1000000000, excludeActiveVersions }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/versions?${sortPageFilterQuery({
			sortField,
			sortDirection,
			page,
			perPage,
			filters: {
				archived_at: 'null',
				...(excludeActiveVersions ? { track: 'not:public' } : {}),
			},
		})}`),
		method: 'GET',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);

export const listArchivedVersionsByGameId = createAuthorizedApiEpic(
	'game/list_archived_versions_by_game_id',
	(callApi, { gameId, sortField, sortDirection, page = 1, perPage = 1000000000 }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/versions?${sortPageFilterQuery({ sortField, sortDirection, page, perPage, filters: { archived_at: 'not:null' } })}`),
		method: 'GET',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);

export const archiveVersion = createAuthorizedApiEpic(
	'game/archive_version',
	(callApi, { gameId, versionId }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/versions/${versionId}/_archive`),
		method: 'POST',
	}),
);

export const unarchiveVersion = createAuthorizedApiEpic(
	'game/unarchive_version',
	(callApi, { gameId, versionId }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/versions/${versionId}/_unarchive`),
		method: 'POST',
	}),
);

export const createGoogleDriveFolderForGame = createAuthorizedApiEpic(
	'game/create_google_drive_folder_for_game',
	(callApi, { gameId }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/_create_google_drive_folder`),
		method: 'POST',
	}),
);

export const request10kGameplays = createAuthorizedApiEpic(
	'game/request_10k_gameplays',
	(callApi, { gameId, versionId }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/_webfit`),
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: {
			version_id: versionId,
		},
	}),
);

export const regenerateUploadToken = createAuthorizedApiEpic(
	'game/regenerate_upload_token',
	(callApi, { gameId }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/_regenerate_upload_token`),
		method: 'POST',
	}),
);

export const createPlayerFeedbackSummary = createAuthorizedApiEpic(
	'game/create_player_feedback_summary',
	(callApi, { id, gameId, prompt, startDate, endDate, types }) => callApi({
		url: getApiUrl('devs', '_player_feedback_summary'),
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: {
			id,
			p4d_game_id: gameId,
			prompt,
			start_date: startDate,
			end_date: endDate,
			types,
		},
	}),
);

export const getPlayerFeedbackSummary = createAuthorizedApiEpic(
	'game/get_player_feedback_summary',
	(callApi, { id }) => callApi({
		url: getApiUrl('devs', `_player_feedback_summary?id=${id}`),
		method: 'GET',
	}),
);

import React, { useMemo, createContext } from 'react';
import styled from 'styled-components';
import { useParentSize } from '@visx/responsive';
import { useTooltip } from '@visx/tooltip';
import { scaleOrdinal } from '@visx/scale';

import ChartTooltip from 'app/src/components/ui/chart/ChartTooltip';
import ChartLoader from 'app/src/components/ui/ChartLoader';
import ChartLegend from 'app/src/components/ui/chart/ChartLegend';

const DEFAULT_MARGINS = { left: 45, bottom: 20, right: 25, top: 10 };

const StateTitle = styled.div`
	text-align: center;
	color: ${props => props.theme.grey3};
	font-size: 16px;
	font-weight: bold;
`;

const StateMessage = styled.div`
	font-size: 12px;
	color: ${props => props.theme.grey3};
	text-align: center;
	line-height: 1.5em;
`;

const State = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	padding: 48px 24px;

	${props => props.error && `
		${StateTitle} {
			color: ${props.theme.rose1};
		}
	`}
`;

export const ChartContext = createContext({
	data: [],
	width: 0,
	height: 400,
	margins: DEFAULT_MARGINS,
	legendDomain: null,
	legendRange: null,
	tooltipData: null,
	tooltipLeft: 0,
	tooltipTop: 0,
	showTooltip: () => {},
	hideTooltip: () => {},
});

const ChartContainer = ({ data, width, height, margins = DEFAULT_MARGINS, legendDomain, legendRange, children, apiStatus }) => {
	const { showTooltip, hideTooltip, tooltipData, tooltipLeft, tooltipTop, tooltipOpen } = useTooltip();
	const { parentRef, width: parentWidth } = useParentSize({ debounceTime: 150 });

	const getMargins = () => ({
		top: margins.top || DEFAULT_MARGINS.top,
		right: margins.right || DEFAULT_MARGINS.right,
		bottom: margins.bottom || DEFAULT_MARGINS.bottom,
		left: margins.left || DEFAULT_MARGINS.left,
	});

	// Memoize the context value to prevent unnecessary re-renders
	const contextValue = useMemo(() => (
		{
			data,
			width: width || parentWidth,
			height,
			margins: getMargins(),
			legendDomain,
			legendRange,
			tooltipData,
			tooltipLeft,
			tooltipTop,
			showTooltip,
			hideTooltip,
		}
	), [data, width, height, margins, parentWidth, legendDomain, legendRange, tooltipData, tooltipLeft, tooltipTop, showTooltip, hideTooltip]);

	return (
		<ChartContext.Provider value={contextValue}>
			<div ref={parentRef}>
				{apiStatus && apiStatus.pending && data.length === 0 ? (
					<State>
						<ChartLoader />
					</State>
				) : apiStatus?.error ? (
					<State error>
						<StateTitle>Problem Loading Data</StateTitle>
						<StateMessage>&quot;{apiStatus.error.message}&quot;</StateMessage>
					</State>
				) : apiStatus?.done && data.length === 0 ? (
					<State>
						<StateTitle>No Data Yet</StateTitle>
						<StateMessage>This can take up to 24 hours, so why not go play some games and check back later!</StateMessage>
					</State>
				) : (
					<>
						{children}
						{tooltipData && tooltipOpen && <ChartTooltip />}
					</>
				)}
			</div>
			{legendDomain && (
				<ChartLegend scale={scaleOrdinal({ domain: legendDomain, range: legendRange })} />
			)}
		</ChartContext.Provider>
	);
};

export default ChartContainer;

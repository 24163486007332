import React from 'react';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { useSelectTheme } from 'app/src/selectors/session';

const tickDefaultProps = {
	fontWeight: 'bold',
	fontSize: 12,
};

export const YAxis = ({ scale, left = 0, tickLabelProps, hideAxisLine, hideTicks }) => {
	const theme = useSelectTheme();

	const defaultTickLabelProps = {
		...tickDefaultProps,
		fill: theme.grey3,
		textAnchor: 'end',
		dx: '-0.25em',
		dy: '0.25em',
	};

	return (
		<AxisLeft
			left={left}
			scale={scale}
			hideAxisLine={hideAxisLine}
			hideTicks={hideTicks}
			tickLabelProps={tickLabelProps || defaultTickLabelProps}
		/>
	);
};

export const XAxis = ({ scale, top = 0, tickLabelProps, hideAxisLine, hideTicks, numTicks }) => {
	const theme = useSelectTheme();

	const defaultTickLabelProps = {
		...tickDefaultProps,
		fill: theme.grey3,
		textAnchor: 'middle',
	};

	return (
		<AxisBottom
			top={top}
			scale={scale}
			hideAxisLine={hideAxisLine}
			hideTicks={hideTicks}
			numTicks={numTicks}
			tickLabelProps={tickLabelProps || defaultTickLabelProps}
		/>
	);
};

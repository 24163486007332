import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import Button from 'app/src/components/ui/Button';
import { setGameChecklistStatus } from 'app/src/actions/session';
import { useSelectUser } from 'app/src/selectors/user';
import { selectLastUploadedVersionByGame } from 'app/src/selectors/game';

const Container = styled.div`
	background: ${props => props.theme.grey7};
	display: flex;
	width: 100%;
	max-width: 444px;
	padding: 32px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 16px;
	border-radius: 4px;
`;

const StyledButton = styled(Button)`
	max-width: unset;
`;

const Buttons = styled.div`
	display: flex;
	gap: 16px;
`;

const Level2Step3 = ({ onSubmitCallback, disabled, game }) => {
	const dispatch = useDispatch();
	const user = useSelectUser();
	const version = selectLastUploadedVersionByGame(game?.versions);
	const isAdmin = user.role === 'admin';

	const onGotItCoveredClick = () => {
		dispatch(setGameChecklistStatus({ gameId: game.id, level: 'playtest', step: 3, status: 'completed' }));
		onSubmitCallback();
	};

	const inspector = version ? `https://inspector.poki.dev/?game=poki-${version.id}${isAdmin ? '&analytics=false' : ''}` : 'https://inspector.poki.dev';

	return (
		<Container>
			<div>
				Games <strong>must not</strong> use external resources.
			</div>
			<div>
				All assets should be bundled in the zip file. This allows our CDN to serve files faster and improves user privacy.
			</div>
			<div>
				Our <a href={inspector} target="_blank" rel="noopener noreferrer">Inspector</a> tool can be used to see external resources being loaded.
			</div>
			<div>
				External resources are automatically blocked by our Content-Security-Policy.
			</div>
			<div>
				If your game requires a dependency like a multiplayer backend, please email <a href="mailto:developers@poki.com">developers@poki.com</a>.
			</div>

			<Buttons>
				<StyledButton onClick={onGotItCoveredClick} disabled={disabled}>The game doesn&apos;t use external resources</StyledButton>
			</Buttons>
		</Container>
	);
};

export default Level2Step3;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelectApiStatus } from '@poki/rx-api';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import moment from 'moment';

import { getPlayerFitTestsByGameId } from 'app/src/epics/playerfit';
import { useSelectPlayerFitTestsByGameId } from 'app/src/selectors/playerfit';
import { getGameById, listGamesByTeamId } from 'app/src/epics/game';
import { useSelectGameVersionsForPlaytests } from 'app/src/selectors/game';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import { useSelectPermissions, useSelectUser } from 'app/src/selectors/user';
import { useSelectTheme } from 'app/src/selectors/session';
import { openModal } from 'app/src/actions/client';
import getVersionLabel from 'app/src/utils/getVersionLabel';
import checkPermissions from 'app/src/utils/checkPermissions';
import formatTime from 'app/src/utils/formatTime';

import PlusIcon from 'shared/designTokens/icons/ui/small/PlusIcon';

import GridContainer from 'app/src/components/ui/GridContainer';
import BarGroupChart from 'app/src/components/ui/chart/charts/BarGroupChart';
import ChartContainer from 'app/src/components/ui/chart/ChartContainer';
import ChartLoader from 'app/src/components/ui/ChartLoader';
import SelectInput from 'app/src/components/input/SelectInput';
import Details from 'app/src/components/ui/Details';
import Table from 'app/src/components/ui/Table';
import Tooltip from 'shared/components/Tooltip';
import Card from 'app/src/components/ui/Card';

import { dayMonthTimeFormat, dayMonthYearTimeFormat, ENGAGEMENT_RANGES } from 'shared/vars';
import playerFitTestsFAQItems from 'shared/utils/playerFitTestsFAQItems';
import _ from 'shared/copy';
import { getPlaytestAudience } from 'shared/utils/playtestAudiences';

const PlayerFitTestPlayed = styled.div`
	color: ${props => props.theme.grey1};
`;

const PlayerFitTestLoader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center; 
	height: 100%;
	width: 100%;

	padding 48px 24px;

	${props => !props.isLoading && `
		display: none;
	`}
`;

const StyledCard = styled(Card)`
	background: none;
	box-shadow: none;
`;

const PlayerFitTestVersionSelectWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 16px 24px;

	@media (min-width: 550px) {
		flex-direction: row;
		padding: 0 24px 24px;
		gap: 12px;
	}
`;

const PlayerFitTestVersionSelect = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const PlayerFitTestVersionIcon = styled.div`
	width: 10px;
	height: 10px;
	background: ${({ $bgColor }) => $bgColor};
	border-radius: 50%;
	margin-top: 30px;
`;

const getFilteredValues = (data, excludedValue) => data
	.filter(d => d.id !== excludedValue && d.durations !== null)
	.map(d => ({
		value: d.id,
		desc: `${d.version.label} - ${moment(d.created_at * 1000).format(dayMonthYearTimeFormat)}`,
	}));

const UPDATE_INTERVAL = 30000; // 30 seconds

const GamePlayerFitTestSubPage = props => {
	const { game } = props;

	const dispatch = useDispatch();
	const initialLoad = useRef(true);

	const user = useSelectUser();
	const isAdmin = user.role === 'admin';
	const theme = useSelectTheme();
	const activeTeam = useSelectActiveTeam();
	const versions = useSelectGameVersionsForPlaytests(game.id);
	const permissions = useSelectPermissions();
	const getPlayerFitTestsByGameIdStatus = useSelectApiStatus(getPlayerFitTestsByGameId.id);
	const { data: playerFitTests = [] } = useSelectPlayerFitTestsByGameId(game.id);

	const [page, setPage] = useState(1);
	const [versionA, setVersionA] = useState('');
	const [versionB, setVersionB] = useState('');
	const [versionsToChart, setVersionsToChart] = useState([]);
	const [legendDomain, setLegendDomain] = useState([]);

	const chartData = useMemo(() => {
		if (versionsToChart.length === 0) {
			return [];
		}

		// Find the selected tests
		const test1 = versionsToChart.find(t => t.id === versionA);
		const test2 = versionsToChart.find(t => t.id === versionB);

		// Set the legend domain based on the selected tests
		if (test1 && test2) {
			// check if labels are the same
			if (test1.version.label === test2.version.label) {
				setLegendDomain([`${test1.version.label} (1)`, `${test2.version.label} (2)`]);
			} else {
				setLegendDomain([test1.version.label, test2.version.label]);
			}
		}

		const { durations: durations1 = [], id: id1 } = test1 || {};
		const { durations: durations2 = [], id: id2 } = test2 || {};

		// Generate chart data based on selected tests
		return ENGAGEMENT_RANGES.map(range => {
			const dataPoint = { range: range.label };

			if (id1) {
				dataPoint[id1] = durations1.filter(value => value >= range.min && value < range.max).length || 0;
			}
			if (id2) {
				dataPoint[id2] = durations2.filter(value => value >= range.min && value < range.max).length || 0;
			}

			return dataPoint;
		});
	}, [versionsToChart, versionA, versionB]);

	const tableLoadingDone = (playerFitTests.length > 0) || getPlayerFitTestsByGameIdStatus.done;
	const canRequestRecordings = checkPermissions(permissions, [['can_edit_all_player_fit_tests', 'can_edit_owned_player_fit_tests']]);

	useEffect(() => {
		if (getPlayerFitTestsByGameIdStatus.done && initialLoad.current) {
			const dataWithDurations = playerFitTests.filter(item => item.durations !== null).slice(0, 2);

			setVersionsToChart(dataWithDurations);

			if (dataWithDurations.length > 1) {
				setVersionA(dataWithDurations[1].id);
			}

			if (dataWithDurations.length > 0) {
				setVersionB(dataWithDurations[0].id);
			}

			initialLoad.current = false;
		}
	}, [getPlayerFitTestsByGameIdStatus.done, playerFitTests]);

	// Fetch a list of recordings when the page opens.
	useEffect(() => {
		dispatch(getPlayerFitTestsByGameId.fetch({ gameId: game.id }));

		const i = setInterval(() => {
			dispatch(getPlayerFitTestsByGameId.fetch({ gameId: game.id }));
			dispatch(listGamesByTeamId.fetch({ teamId: activeTeam.id }));
			dispatch(getGameById.fetch(game.id));
		}, UPDATE_INTERVAL);

		return () => {
			clearInterval(i);
		};
	}, []);

	const onSuccessCallback = () => {
		dispatch(getGameById.fetch(game.id));
	};

	const handleStartNewPlayerFitTest = () => {
		const { id, approved, content_metadata: { release_status } = {} } = game;

		dispatch(openModal({
			key: 'start-new-playerfit-test',
			data: { gameId: id, gameApproved: approved, releaseStatus: release_status, onSuccessCallback },
		}));
	};

	const handleVersionChange = (newVersionA, newVersionB) => {
		setVersionA(newVersionA);
		setVersionB(newVersionB);

		setVersionsToChart([
			playerFitTests.find(t => t.id === newVersionA),
			playerFitTests.find(t => t.id === newVersionB),
		]);
	};

	return (
		<>
			<Helmet key={`GamePlayerFitTestSubPage-${game.id}-${activeTeam.code}`}>
				<title>Player Fit Tests - {game.title} - {activeTeam.name} - Poki for Developers</title>
			</Helmet>
			<GridContainer cols={1}>
				<Card
					title="Player Fit Tests"
					description={versionsToChart.length > 1 ? 'Select two versions to compare the number of players across different play duration ranges.' : ''}
					elevated
					noPadding
					buttons={[
						...((canRequestRecordings && [
							{
								id: 'start-new-playerfit-test',
								type: 'button',
								icon: PlusIcon,
								action: handleStartNewPlayerFitTest,
								disabled: versions.length === 0,
								children: 'New Player Fit Test',
							},
						]) || []),
					]}
				>
					{!tableLoadingDone ? (
						<PlayerFitTestLoader isLoading={!tableLoadingDone}>
							<ChartLoader />
						</PlayerFitTestLoader>
					) : (
						<>
							{versionsToChart.length > 1 ? (
								<PlayerFitTestVersionSelectWrapper>
									<PlayerFitTestVersionSelect>
										<PlayerFitTestVersionIcon $bgColor={theme.dataBlue5} />
										<SelectInput
											name="first-version-to-compare"
											label={_`firstVersionToCompare`}
											value={versionA || versionsToChart[0]?.id}
											values={getFilteredValues(playerFitTests, versionB)}
											valueSetter={newVersion => handleVersionChange(newVersion, versionB)}
											small
										/>
									</PlayerFitTestVersionSelect>
									<PlayerFitTestVersionSelect>
										<PlayerFitTestVersionIcon $bgColor={theme.dataBlue3} />
										<SelectInput
											name="second-version-to-compare"
											label={_`secondVersionToCompare`}
											value={versionB || versionsToChart[1]?.id}
											values={getFilteredValues(playerFitTests, versionA)}
											valueSetter={newVersion => handleVersionChange(versionA, newVersion)}
											small
										/>
									</PlayerFitTestVersionSelect>
								</PlayerFitTestVersionSelectWrapper>
							) : null}
							{versionsToChart.length > 0 ? (
								<ChartContainer
									data={chartData}
									height={350}
									margins={{ left: 50, right: 20, bottom: 40 }}
									legendDomain={legendDomain}
									legendRange={[theme.dataBlue5, theme.dataBlue3]}
								>
									<BarGroupChart xKey="range" />
								</ChartContainer>
							) : null}
							<Table
								title="PlayerFitTests"
								items={playerFitTests || []}
								setPagination={setPage}
								page={page}
								perPage={20}
								totalItems={(playerFitTests || []).length}
								autoPaging
								columns={[
									{
										title: _`when`,
										content: ({ item }) => (
											<PlayerFitTestPlayed>
												{moment(item.created_at * 1000).format(dayMonthTimeFormat)}
											</PlayerFitTestPlayed>
										),
									},
									{
										title: 'Status',
										content: ({ item }) => (
											<Tooltip
												arrow
												placement="top-end"
												maxWidth="250px"
												content={`In ${formatTime((item.stopped_at || (Date.now() / 1000)) - item.created_at, true, true)}`}
											>
												{item.stopped_at ? 'completed' : 'in progress'}
											</Tooltip>
										),
									},
									{
										title: _`version`,
										content: ({ item }) => {
											const version = game.versions.find(v => v.id === item.version_id);

											return version ? getVersionLabel(version) : 'Unknown';
										},
									},
									{
										title: 'Audience',
										content: ({ item }) => getPlaytestAudience(item),
									},
									(isAdmin ? {
										title: 'Countries',
										content: ({ item }) => item.countries || 'All',
									} : null),
									{
										title: 'Gameplays',
										content: ({ item }) => item.gameplays,
									},
									{
										title: 'Average Playtime',
										content: ({ item }) => (formatTime(item.engagement, false)),
									},
								]}
							/>
						</>
					)}
				</Card>
				<StyledCard title="F.A.Q">
					{playerFitTestsFAQItems.map(({ isInitiallyOpen, question, answer }) => (
						<Details key={question} title={question} content={answer} isInitiallyOpen={isInitiallyOpen} />
					))}
				</StyledCard>
			</GridContainer>
		</>
	);
};

export default GamePlayerFitTestSubPage;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { useSelectActiveTeam } from 'app/src/selectors/team';
import { checklistSteps, getCurrentLevel, isSSFStageAfter } from 'app/src/utils/selfServiceFlow';
import { useSelectGameChecklistStatus } from 'app/src/selectors/session';
import { setGameChecklistStatus } from 'app/src/actions/session';

import ChecklistCard from 'app/src/components/ui/ChecklistCard';
import Level1CreateGame from 'app/src/components/pages/game/checklist/Level1CreateGame';
import PlaytestStep1 from 'app/src/components/pages/game/checklist/PlaytestStep1';
import PlaytestStep2 from 'app/src/components/pages/game/checklist/PlaytestStep2';
import PlaytestStep3 from 'app/src/components/pages/game/checklist/PlaytestStep3';
import PlaytestStep4 from 'app/src/components/pages/game/checklist/PlaytestStep4';
import PlayerFitTest from 'app/src/components/pages/game/checklist/PlayerFitTest';
import WebFitStep1 from 'app/src/components/pages/game/checklist/WebFitStep1';
import WebFitStep2 from 'app/src/components/pages/game/checklist/WebFitStep2';
import WebFitStep3 from 'app/src/components/pages/game/checklist/WebFitStep3';
import Level4Description from 'app/src/components/pages/game/checklist/Level4Description';

import _ from 'shared/copy';

const ChecklistWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	flex: 1 0 0;
`;

const GameOverviewChecklistModule = ({ game = {} }) => {
	const dispatch = useDispatch();

	const [activeLevel, setActiveLevel] = useState(0);

	const activeTeam = useSelectActiveTeam();
	const checklistStatus = useSelectGameChecklistStatus(game?.id);

	const isGameJam = activeTeam?.customer_segment === 'game-jam';
	const isTeamVerified = activeTeam?.verified;

	const current = getCurrentLevel(game, isGameJam, checklistStatus);

	// Make sure checklist steps are completed automatically when a game reaches a certain stage.
	// This makes sure everything is nicely checked off in the UI.
	useEffect(() => {
		const updateChecklist = (level, steps) => {
			steps.forEach(({ step, requiredStage }) => {
				if (isSSFStageAfter(game.self_service_stage, requiredStage) && checklistStatus?.[level]?.[step - 1]?.status !== 'completed') {
					dispatch(setGameChecklistStatus({ gameId: game.id, level, step, status: 'completed' }));
				}
			});
		};

		checklistSteps.forEach(({ level, steps }) => updateChecklist(level, steps));
	}, [game.self_service_stage, checklistStatus, dispatch]);

	useEffect(() => {
		setActiveLevel(current);
	}, [current]);

	const onClickCard = level => {
		setActiveLevel(level);
	};

	return (
		<ChecklistWrapper>
			<ChecklistCard
				id="create-game"
				title={_`addYourGame`}
				level="1"
				subComponent={(<Level1CreateGame />)}
				icon="upload"
				active={activeLevel === 0}
				current={current >= 0}
				completed={current > 0}
				onClick={current === 0 ? () => onClickCard(0) : undefined}
			/>
			<ChecklistCard
				id="playtest"
				title={_`getPlayerFeedback`}
				level="2"
				description={_`startGetPlayerFeedback`}
				checkType="coin"
				active={activeLevel === 1}
				current={current >= 1}
				completed={current > 1}
				game={game}
				onClick={() => onClickCard(1)}
				icon="game"
				noPadding
				steps={[
					{
						title: _`yourGamePreviewLink`,
						contentGenerator: PlaytestStep1,
					},
					{
						title: _`prepareYourGameForWeb`,
						contentGenerator: PlaytestStep2,
					},
					{
						title: _`embedExternalResources`,
						contentGenerator: PlaytestStep3,
					},
					{
						title: _`startPlaytesting`,
						contentGenerator: PlaytestStep4,
					},
				]}
			/>
			<ChecklistCard
				id="playerfit"
				title="Player Fit Test"
				level="3"
				description={_`playerFitSubtitle`}
				subComponent={(<PlayerFitTest game={game} disabled={current !== 2} />)}
				active={activeLevel === 2}
				current={current >= 2}
				completed={current > 2}
				game={game}
				onClick={() => onClickCard(2)}
				icon="graph"
				steps={[]}
			/>
			<ChecklistCard
				id="webfit"
				title={_`pokiWebFitTest`}
				level="4"
				description={_`feelingYourGameIsReady`}
				checkType="diamond"
				active={activeLevel === 3}
				current={current >= 3}
				completed={current > 3}
				game={game}
				onClick={() => onClickCard(3)}
				icon="diamond"
				steps={[
					{
						title: _`gameThumbnail`,
						contentGenerator: WebFitStep1,
					},
					{
						title: _`webFitStarts`,
						contentGenerator: WebFitStep2,
					},
					{
						title: _`webFitTestResults`,
						contentGenerator: WebFitStep3,
					},
				]}
			/>
			<ChecklistCard
				id="release-game"
				title={_`releaseYourGameOnPoki`}
				level="5"
				subComponent={(<Level4Description isTeamVerified={isTeamVerified} />)}
				active={activeLevel === 4}
				current={current >= 4}
				game={game}
				onClick={() => onClickCard(4)}
				icon="rocket"
				steps={[]}
			/>
		</ChecklistWrapper>
	);
};

export default GameOverviewChecklistModule;

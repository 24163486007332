import moment from 'moment';
import measures from 'app/src/measures';

import { createAuthorizedApiEpic, formatDateAsString } from 'app/src/utils/api';
import getApiUrl from 'app/src/utils/getApiUrl';
import { clickhouseDataCacheSeconds } from 'shared/vars';
import filtersToExpressions from 'app/src/utils/filtersToExpressions';

export const getGameplaysPerReferrerForGameId = createAuthorizedApiEpic(
	'data/get_gameplays_per_referrer_for_game_id',
	(callApi, { gameId, teamId, from, to, deviceCategories, countries, contexts, versions }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_gameplays_per_referrer_group',
				select: [
					{
						field: 'gameplays',
						aggregate: 'sum',
					},
					{
						field: 'date',
					},
					{
						field: 'referrer_group',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
						countries?.length > 0 ? ['country_id', 'in', countries] : undefined,
						contexts?.length > 0 ? ['context', 'in', contexts] : undefined,
						versions?.length > 0 ? ['p4d_game_version_id', 'in', versions] : undefined,
					],
				},
				group: [
					'date',
					'referrer_group',
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getGameplaysForTeamId = createAuthorizedApiEpic(
	'data/get_gameplays_for_team',
	(callApi, { teamId, notTeamId, from, to, deviceCategories }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_gameplays',
				select: [
					{
						field: 'gameplays',
						aggregate: 'sum',
					},
					{
						field: 'date',
					},
					{
						field: 'device_category',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						notTeamId ? ['team_id', '!=', notTeamId] : undefined,
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
					],
				},
				group: [
					'date',
					'device_category',
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getEarliestGameplaysForGameId = createAuthorizedApiEpic(
	'data/get_earliest_gameplays_game_id',
	(callApi, { gameId, teamId, deviceCategories, countries, contexts, versions }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_gameplays',
				select: [
					{
						field: 'date',
						aggregate: 'min',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
						countries?.length > 0 ? ['country_id', 'in', countries] : undefined,
						contexts?.length > 0 ? ['context', 'in', contexts] : undefined,
						versions?.length > 0 ? ['p4d_game_version_id', 'in', versions] : undefined,
					],
				},
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getEarningsForTeamId = createAuthorizedApiEpic(
	'data/get_earnings_for_team',
	(callApi, { teamId, notTeamId, from, to, currency, deviceCategories }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_developer_earnings',
				select: [
					{
						field: currency === 'usd' ? 'developer_earnings_usd' : 'developer_earnings_eur',
						aggregate: 'sum',
						alias: 'developer_earnings',
					},
					{
						field: 'date',
					},
					{
						field: 'device_category',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						notTeamId ? ['team_id', '!=', notTeamId] : undefined,
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
					],
				},
				group: [
					'date',
					'device_category',
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getEarningsForGameId = createAuthorizedApiEpic(
	'data/get_earnings_game_id',
	(callApi, { gameId, teamId, from, to, currency, deviceCategories, contexts, countries }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_developer_earnings',
				select: [
					{
						field: currency === 'usd' ? 'developer_earnings_usd' : 'developer_earnings_eur',
						aggregate: 'sum',
						alias: 'developer_earnings',
					},
					{
						field: 'date',
					},
					{
						field: 'device_category',
					},
				],
				where: {
					expressions: [
						['team_id', '==', teamId],
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
						countries?.length > 0 ? ['country_id', 'in', countries] : undefined,
						contexts?.length > 0 ? ['context', 'in', contexts] : undefined,
					],
				},
				group: [
					'date',
					'device_category',
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getEarningsPerReferrerForGameId = createAuthorizedApiEpic(
	'data/get_earnings_per_referrer_game_id',
	(callApi, { gameId, teamId, from, to, currency, deviceCategories, contexts, countries }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_developer_earnings_per_referrer_group',
				select: [
					{
						field: currency === 'usd' ? 'developer_earnings_usd' : 'developer_earnings_eur',
						aggregate: 'sum',
						alias: 'developer_earnings',
					},
					{
						field: 'date',
					},
					{
						field: 'referrer_group',
					},
				],
				where: {
					expressions: [
						['team_id', '==', teamId],
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
						countries?.length > 0 ? ['country_id', 'in', countries] : undefined,
						contexts?.length > 0 ? ['context', 'in', contexts] : undefined,
					],
				},
				group: [
					'date',
					'referrer_group',
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getTotalEarningsForTeamId = createAuthorizedApiEpic(
	'data/get_total_earnings_for_team',
	(callApi, { teamId, notTeamId, from, to, currency, deviceCategories }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_developer_earnings',
				select: [
					{
						field: currency === 'usd' ? 'developer_earnings_usd' : 'developer_earnings_eur',
						aggregate: 'sum',
						alias: 'developer_earnings',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						notTeamId ? ['team_id', '!=', notTeamId] : undefined,
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
					],
				},
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getTotalEarningsPrevPeriodForTeamId = createAuthorizedApiEpic(
	'data/get_total_earnings_prev_period_for_team',
	(callApi, { teamId, from, to, currency, deviceCategories }) => {
		const fromMoment = moment.utc(from);
		const toMoment = moment.utc(to);
		const dayDiff = toMoment.diff(fromMoment, 'day') + 1;
		const prevPeriodFrom = fromMoment.clone().subtract(dayDiff, 'day');
		const prevPeriodTo = toMoment.clone().subtract(dayDiff, 'day');

		return callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_developer_earnings',
				select: [
					{
						field: currency === 'usd' ? 'developer_earnings_usd' : 'developer_earnings_eur',
						aggregate: 'sum',
						alias: 'developer_earnings',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['date', '>=', formatDateAsString(prevPeriodFrom)],
						['date', '<=', formatDateAsString(prevPeriodTo)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
					],
				},
			},
		});
	},
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getTotalGameplaysForTeamId = createAuthorizedApiEpic(
	'data/get_total_gameplays_for_team',
	(callApi, { teamId, notTeamId, from, to, deviceCategories }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_gameplays',
				select: [
					{
						field: 'gameplays',
						aggregate: 'sum',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						notTeamId ? ['team_id', '!=', notTeamId] : undefined,
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
					],
				},
				group: [],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getTotalGameplaysPrevPeriodForTeamId = createAuthorizedApiEpic(
	'data/get_total_gameplays_prev_period_for_team',
	(callApi, { teamId, notTeamId, from, to, deviceCategories }) => {
		const fromMoment = moment.utc(from);
		const toMoment = moment.utc(to);
		const dayDiff = toMoment.diff(fromMoment, 'day') + 1;
		const prevPeriodFrom = fromMoment.clone().subtract(dayDiff, 'day');
		const prevPeriodTo = toMoment.clone().subtract(dayDiff, 'day');

		return callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_gameplays',
				select: [
					{
						field: 'gameplays',
						aggregate: 'sum',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						notTeamId ? ['team_id', '!=', notTeamId] : undefined,
						['date', '>=', formatDateAsString(prevPeriodFrom)],
						['date', '<=', formatDateAsString(prevPeriodTo)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
					],
				},
				group: [],
			},
		});
	},
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getGameErrorVersions = createAuthorizedApiEpic(
	'data/get_game_error_versions',
	(callApi, { gameId, teamId, from, to, filters }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_game_errors_per_gameplay',
				select: [
					{
						field: 'gameplay_id',
						aggregate: 'count',
						distinct: true,
						alias: 'gameplays',
						condition: {
							expressions: filtersToExpressions(filters).filter(([field]) => field !== 'p4d_game_version_id'),
						},
					},
					{
						field: 'p4d_game_version_id',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
						['date_hour', '>=', from.format('YYYY-MM-DD HH:mm:ss')],
						['date_hour', '<=', to.format('YYYY-MM-DD HH:mm:ss')],
					],
				},
				group: [
					'p4d_game_version_id',
				],
				order: [
					{
						field: 'gameplays',
						direction: 'desc',
					},
				],
				limit: 100,
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getGameErrorDeviceCategories = createAuthorizedApiEpic(
	'data/get_game_error_device_categories',
	(callApi, { gameId, teamId, from, to, filters }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_game_errors_per_gameplay',
				select: [
					{
						field: 'gameplay_id',
						aggregate: 'count',
						distinct: true,
						alias: 'gameplays',
						condition: {
							expressions: filtersToExpressions(filters).filter(([field]) => field !== 'device_category'),
						},
					},
					{
						field: 'device_category',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
						['date_hour', '>=', from.format('YYYY-MM-DD HH:mm:ss')],
						['date_hour', '<=', to.format('YYYY-MM-DD HH:mm:ss')],
					],
				},
				group: [
					'device_category',
				],
				order: [
					{
						field: 'gameplays',
						direction: 'desc',
					},
				],
				limit: 100,
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getGameErrorBrowserNames = createAuthorizedApiEpic(
	'data/get_game_error_browser_names',
	(callApi, { gameId, teamId, from, to, filters }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_game_errors_per_gameplay',
				select: [
					{
						field: 'gameplay_id',
						aggregate: 'count',
						distinct: true,
						alias: 'gameplays',
						condition: {
							expressions: filtersToExpressions(filters).filter(([field]) => field !== 'browser_name'),
						},
					},
					{
						field: 'browser_name',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
						['date_hour', '>=', from.format('YYYY-MM-DD HH:mm:ss')],
						['date_hour', '<=', to.format('YYYY-MM-DD HH:mm:ss')],
					],
				},
				group: [
					'browser_name',
				],
				order: [
					{
						field: 'gameplays',
						direction: 'desc',
					},
				],
				limit: 100,
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getErrorGameplaysForGame = createAuthorizedApiEpic(
	'data/get_error_gameplays_for_game',
	(callApi, { gameId, teamId, from, to }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_game_errors_gameplays',
				select: [
					{
						field: 'gameplays',
						aggregate: 'sum',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
						['date_hour', '>=', from.format('YYYY-MM-DD HH:mm:ss')],
						['date_hour', '<=', to.format('YYYY-MM-DD HH:mm:ss')],
					],
				},
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getErrorsForGame = createAuthorizedApiEpic(
	'data/get_errors_for_game',
	(callApi, { gameId, teamId, from, to, sortField, sortDirection, page = 1, perPage = 1000000000, filters }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_game_errors_per_gameplay',
				select: [
					{
						field: 'errors',
						aggregate: 'sum',
						alias: 'total_errors', // Can't be "errors" as it's used as a weight below
					},
					{
						field: 'gameplay_id',
						aggregate: 'count',
						distinct: true,
						alias: 'gameplays',
					},
					{
						field: 'error_name',
					},
					{
						field: 'error_message',
					},
					{
						field: 'error_stack',
						aggregate: 'topKWeighted',
						weight: 'errors',
					},
					{
						field: 'stack_line',
						aggregate: 'topKWeighted',
						weight: 'errors',
					},
					{
						field: 'error_id',
					},
					{
						field: 'same_engine_games',
						aggregate: 'max',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
						['date_hour', '>=', from.format('YYYY-MM-DD HH:mm:ss')],
						['date_hour', '<=', to.format('YYYY-MM-DD HH:mm:ss')],
						...filtersToExpressions(filters),
					],
				},
				group: [
					'error_name',
					'error_message',
					'error_id',
				],
				order: [
					{
						field: sortField,
						direction: sortDirection > 0 ? 'asc' : 'desc',
					},
				],
				offset: (page - 1) * perPage,
				limit: perPage,
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getErrorDetailsForErrorId = createAuthorizedApiEpic(
	'data/get_error_details_for_error',
	(callApi, { errorId, gameId, teamId, from, to, filters, sortField, sortDirection, page = 1, perPage = 1000000000 }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_game_errors_per_gameplay',
				select: [
					{
						field: 'errors',
						aggregate: 'sum',
						alias: 'errors',
					},
					{
						field: 'gameplay_id',
						aggregate: 'count',
						distinct: true,
						alias: 'gameplays',
					},
					{
						field: 'browser_name',
					},
					{
						field: 'browser_version',
					},
					{
						field: 'device_category',
					},
					{
						field: 'p4d_game_version_id',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['error_id', '==', errorId],
						['p4d_game_id', '==', gameId],
						['date_hour', '>=', from.format('YYYY-MM-DD HH:mm:ss')],
						['date_hour', '<=', to.format('YYYY-MM-DD HH:mm:ss')],
						...filtersToExpressions(filters),
					],
				},
				group: [
					'browser_name',
					'browser_version',
					'device_category',
					'p4d_game_version_id',
				],
				order: [
					{
						field: sortField,
						direction: sortDirection > 0 ? 'asc' : 'desc',
					},
				],
				offset: (page - 1) * perPage,
				limit: perPage,
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getPlayerFeedbackTotalForGame = createAuthorizedApiEpic(
	'data/get_player_feedback_total_for_game',
	(callApi, { gameId, teamId }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_player_feedback',
				select: [
					{
						aggregate: 'count',
						alias: 'count',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
					],
				},
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getPlayerFeedbackCountriesForGame = createAuthorizedApiEpic(
	'data/get_player_feedback_countries_for_game',
	(callApi, { gameId, teamId, filters }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_player_feedback',
				select: [
					{
						aggregate: 'count',
						alias: 'count',
						condition: {
							expressions: filtersToExpressions(filters).filter(([field]) => field !== 'country_code'),
						},
					},
					{
						field: 'country',
					},
					{
						field: 'country_code',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
					],
				},
				group: [
					'country',
					'country_code',
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getPlayerFeedbackRatingTypesForGame = createAuthorizedApiEpic(
	'data/get_player_feedback_rating_types_for_game',
	(callApi, { gameId, teamId, filters }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_player_feedback',
				select: [
					{
						aggregate: 'count',
						alias: 'count',
						condition: {
							expressions: filtersToExpressions(filters).filter(([field]) => field !== 'type'),
						},
					},
					{
						field: 'type',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
					],
				},
				group: [
					'type',
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getPlayerFeedbackQualityTypesForGame = createAuthorizedApiEpic(
	'data/get_player_feedback_quality_types_for_game',
	(callApi, { gameId, teamId, filters }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_player_feedback',
				select: [
					{
						aggregate: 'count',
						alias: 'count',
						condition: {
							expressions: filtersToExpressions(filters).filter(([field]) => field !== 'probably_spammy'),
						},
					},
					{
						field: 'probably_spammy',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
					],
				},
				group: [
					'probably_spammy',
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getGameEventsForGame = createAuthorizedApiEpic(
	'data/get_game_events_for_game',
	(callApi, { gameId, teamId, category, action, label, from, to, sortField, sortDirection }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_game_events',
				select: [
					{
						field: 'category',
					},
					{
						field: 'action',
					},
					{
						field: 'label',
					},
					{
						field: 'users',
						aggregate: 'sum',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						category?.length > 0 ? ['category', 'in', category] : undefined,
						action?.length > 0 ? ['action', 'in', action] : undefined,
						label?.length > 0 ? ['label', 'in', label] : undefined,
					],
				},
				group: [
					'category',
					'action',
					'label',
				],
				order: [
					{
						field: sortField,
						direction: sortDirection > 0 ? 'asc' : 'desc',
						numeric: sortField === 'action' || sortField === 'label',
					},
				],
				limit: 1000,
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getGameEventCategoriesForGame = createAuthorizedApiEpic(
	'data/get_game_event_categories_for_game',
	(callApi, { gameId, teamId, from, to, action, label }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_game_events',
				select: [
					{
						field: 'category',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						action?.length > 0 ? ['action', 'in', action] : undefined,
						label?.length > 0 ? ['label', 'in', label] : undefined,
					],
				},
				group: [
					'category',
				],
				order: [
					{
						field: 'category',
						direction: 'asc',
					},
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getGameEventActionsForGame = createAuthorizedApiEpic(
	'data/get_game_event_action_for_game',
	(callApi, { gameId, teamId, from, to, category, label }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_game_events',
				select: [
					{
						field: 'action',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						category?.length > 0 ? ['category', 'in', category] : undefined,
						label?.length > 0 ? ['label', 'in', label] : undefined,
					],
				},
				group: [
					'action',
				],
				order: [
					{
						field: 'action',
						direction: 'asc',
					},
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getGameEventLabelsForGame = createAuthorizedApiEpic(
	'data/get_game_event_label_for_game',
	(callApi, { gameId, teamId, from, to, category, action }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_game_events',
				select: [
					{
						field: 'label',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						category?.length > 0 ? ['category', 'in', category] : undefined,
						action?.length > 0 ? ['action', 'in', action] : undefined,
					],
				},
				group: [
					'label',
				],
				order: [
					{
						field: 'label',
						direction: 'asc',
					},
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getTotalUsersForGame = createAuthorizedApiEpic(
	'data/get_total_users_for_game',
	(callApi, { gameId, teamId, from, to }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_games_overview',
				select: [
					{
						alias: 'users',
						aggregate: 'sum',
						field: 'daily_active_users',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
					],
				},
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getPlayerFeedbackVersionsForGame = createAuthorizedApiEpic(
	'data/get_player_feedback_versions_for_game',
	(callApi, { gameId, teamId, filters }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_player_feedback',
				select: [
					{
						aggregate: 'count',
						alias: 'count',
						condition: {
							expressions: filtersToExpressions(filters).filter(([field]) => field !== 'p4d_version_id'),
						},
					},
					{
						field: 'p4d_version_id',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
					],
				},
				group: [
					'p4d_version_id',
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getPlayerFeedbackForGame = createAuthorizedApiEpic(
	'data/get_player_feedback_for_game',
	(callApi, { gameId, teamId, page, perPage, sortField, sortDirection, filters }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_player_feedback',
				select: [
					{
						field: 'timestamp',
					},
					{
						field: 'type',
					},
					{
						field: 'country',
					},
					{
						field: 'country_code',
					},
					{
						field: 'message',
					},
					{
						field: 'english_message',
					},
					{
						field: 'browser_name',
					},
					{
						field: 'browser_version',
					},
					{
						field: 'os_name',
					},
					{
						field: 'os_version',
					},
					{
						field: 'device_category',
					},
					{
						field: 'p4d_version_id',
					},
					{
						field: 'has_adblock',
					},
					{
						field: 'game_resolution',
					},
					{
						field: 'was_fullscreen_this_gameplay',
					},
					{
						field: 'loading_finished',
					},
					{
						field: 'gametime_seconds',
					},
					{
						field: 'errors',
					},
					{
						field: 'webgl_renderer',
					},
					{
						field: 'device_pixel_ratio',
					},
					{
						field: 'screenshot_url',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
						...filtersToExpressions(filters),
					],
				},
				order: [
					{
						field: sortField,
						direction: sortDirection > 0 ? 'asc' : 'desc',
					},
					{
						field: 'timestamp',
						direction: 'desc',
					},
				],
				offset: (page - 1) * perPage,
				limit: perPage,
				include: {
					p4d_version_id: {
						type: 'game_versions',
					},
				},
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getMonetizationForGameId = createAuthorizedApiEpic(
	'data/get_monetization_game_id',
	(callApi, { gameId, teamId, from, to, deviceCategories, countries, contexts }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_monetization',
				select: [
					{
						field: 'daily_active_users',
						aggregate: 'sum',
					},
					{
						field: 'ingame_display_impressions',
						aggregate: 'sum',
					},
					{
						field: 'gamebar_display_impressions',
						aggregate: 'sum',
					},
					{
						field: 'platform_display_impressions',
						aggregate: 'sum',
					},
					{
						field: 'preroll_video_impressions',
						aggregate: 'sum',
					},
					{
						field: 'midroll_video_impressions',
						aggregate: 'sum',
					},
					{
						field: 'rewarded_video_impressions',
						aggregate: 'sum',
					},
					{
						field: 'date',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
						countries?.length > 0 ? ['country_id', 'in', countries] : undefined,
						contexts?.length > 0 ? ['context', 'in', contexts] : undefined,
					],
				},
				group: [
					'date',
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getTotalMonetizationForGameId = createAuthorizedApiEpic(
	'data/get_total_monetization_game_id',
	(callApi, { gameId, teamId, from, to, deviceCategories, countries, contexts, currency }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_monetization',
				select: [
					{
						field: 'daily_active_users',
						aggregate: 'sum',
					},
					{
						field: 'ingame_display_impressions',
						aggregate: 'sum',
					},
					{
						field: 'gamebar_display_impressions',
						aggregate: 'sum',
					},
					{
						field: 'platform_display_impressions',
						aggregate: 'sum',
					},
					{
						field: 'preroll_video_impressions',
						aggregate: 'sum',
					},
					{
						field: 'midroll_video_impressions',
						aggregate: 'sum',
					},
					{
						field: 'rewarded_video_impressions',
						aggregate: 'sum',
					},
					{
						field: currency === 'usd' ? 'ingame_display_developer_earnings_usd' : 'ingame_display_developer_earnings_eur',
						aggregate: 'sum',
						alias: 'ingame_display_developer_earnings',
					},
					{
						field: currency === 'usd' ? 'gamebar_display_developer_earnings_usd' : 'gamebar_display_developer_earnings_eur',
						aggregate: 'sum',
						alias: 'gamebar_display_developer_earnings',
					},
					{
						field: currency === 'usd' ? 'platform_display_developer_earnings_usd' : 'platform_display_developer_earnings_eur',
						aggregate: 'sum',
						alias: 'platform_display_developer_earnings',
					},
					{
						field: currency === 'usd' ? 'preroll_video_developer_earnings_usd' : 'preroll_video_developer_earnings_eur',
						aggregate: 'sum',
						alias: 'preroll_video_developer_earnings',
					},
					{
						field: currency === 'usd' ? 'midroll_video_developer_earnings_usd' : 'midroll_video_developer_earnings_eur',
						aggregate: 'sum',
						alias: 'midroll_video_developer_earnings',
					},
					{
						field: currency === 'usd' ? 'rewarded_video_developer_earnings_usd' : 'rewarded_video_developer_earnings_eur',
						aggregate: 'sum',
						alias: 'rewarded_video_developer_earnings',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
						countries?.length > 0 ? ['country_id', 'in', countries] : undefined,
						contexts?.length > 0 ? ['context', 'in', contexts] : undefined,
					],
				},
				group: [
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getTotalMonetizationPrevPeriodForGameId = createAuthorizedApiEpic(
	'data/get_total_monetization_prev_periodgame_id',
	(callApi, { gameId, teamId, from, to, deviceCategories, countries, contexts }) => {
		const fromMoment = moment.utc(from);
		const toMoment = moment.utc(to);
		const dayDiff = toMoment.diff(fromMoment, 'day') + 1;
		const prevPeriodFrom = fromMoment.clone().subtract(dayDiff, 'day');
		const prevPeriodTo = toMoment.clone().subtract(dayDiff, 'day');

		return callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_monetization',
				select: [
					{
						field: 'daily_active_users',
						aggregate: 'sum',
					},
					{
						field: 'ingame_display_impressions',
						aggregate: 'sum',
					},
					{
						field: 'gamebar_display_impressions',
						aggregate: 'sum',
					},
					{
						field: 'platform_display_impressions',
						aggregate: 'sum',
					},
					{
						field: 'preroll_video_impressions',
						aggregate: 'sum',
					},
					{
						field: 'midroll_video_impressions',
						aggregate: 'sum',
					},
					{
						field: 'rewarded_video_impressions',
						aggregate: 'sum',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
						['date', '>=', formatDateAsString(prevPeriodFrom)],
						['date', '<=', formatDateAsString(prevPeriodTo)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
						countries?.length > 0 ? ['country_id', 'in', countries] : undefined,
						contexts?.length > 0 ? ['context', 'in', contexts] : undefined,
					],
				},
				group: [
				],
			},
		});
	},
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getQuickStatsForTeam = createAuthorizedApiEpic(
	'data/get_quick_stats_for_team',
	(callApi, { teamId, currency }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_quick_stats',
				select: [
					{
						field: currency === 'usd' ? 'yesterday_developer_earnings_usd' : 'yesterday_developer_earnings_eur',
						alias: 'yesterday_developer_earnings',
					},
					{
						field: currency === 'usd' ? 'last_7_days_developer_earnings_usd' : 'last_7_days_developer_earnings_eur',
						alias: 'last_7_days_developer_earnings',
					},
					{
						field: currency === 'usd' ? 'last_14_days_developer_earnings_usd' : 'last_14_days_developer_earnings_eur',
						alias: 'last_14_days_developer_earnings',
					},
					{
						field: currency === 'usd' ? 'current_month_developer_earnings_usd' : 'current_month_developer_earnings_eur',
						alias: 'current_month_developer_earnings',
					},
					{
						field: currency === 'usd' ? 'all_time_developer_earnings_usd' : 'all_time_developer_earnings_eur',
						alias: 'all_time_developer_earnings',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
					],
				},
				limit: 1,
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getTablesLastUpdatedAt = createAuthorizedApiEpic(
	'data/get_tables_last_updated_at',
	callApi => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'table_update_times',
				select: [
					{
						field: 'last_updated_at',
					},
					{
						field: 'table_name',
					},
				],
				where: {
					expressions: [
						['table_name', 'in',
							[
								'dbt_p4d_developer_earnings',
								'dbt_p4d_engagement',
								'dbt_p4d_game_errors_per_gameplay',
								'dbt_p4d_gameplays',
								'dbt_p4d_games_overview',
								'dbt_p4d_monetization',
								'dbt_p4d_player_feedback',
								'dbt_p4d_users',
							],
						],
					],
				},
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getGameplayCountriesForGame = createAuthorizedApiEpic(
	'data/get_gameplay_countries_for_game',
	(callApi, { gameId, teamId }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_gameplays',
				select: [
					{
						field: 'gameplays',
						aggregate: 'sum',
						alias: 'gameplays',
					},
					{
						field: 'country_id',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
					],
				},
				group: [
					'country_id',
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getRevenueCountriesForGame = createAuthorizedApiEpic(
	'data/get_revenue_countries_for_game',
	(callApi, { gameId, teamId, currency }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_developer_earnings',
				select: [
					{
						field: currency === 'usd' ? 'developer_earnings_usd' : 'developer_earnings_eur',
						aggregate: 'sum',
						alias: 'developer_earnings',
					},
					{
						field: 'country_id',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', '==', gameId],
					],
				},
				group: [
					'country_id',
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getGamesOverviewTrend = createAuthorizedApiEpic(
	'data/get_games_overview_trend',
	(callApi, { gameIds, from, to, teamId, currency, deviceCategories }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_games_overview',
				select: [
					{
						alias: 'release_status_changed_at',
						aggregate: 'max',
						field: 'release_status_changed_at',
					},
					{
						alias: 'num_domains_live',
						aggregate: 'max',
						field: 'num_domains_live',
					},
					{
						field: 'date',
					},
					{
						field: 'p4d_game_id',
					},
					{
						field: 'team_id',
					},
					{
						alias: 'gameplays',
						aggregate: 'sum',
						field: 'gameplays',
					},
					{
						alias: 'developer_earnings',
						aggregate: 'sum',
						field: currency === 'usd' ? 'developer_earnings_usd' : 'developer_earnings_eur',
					},
					{
						alias: 'daily_active_users',
						aggregate: 'sum',
						field: 'daily_active_users',
					},
					{
						alias: 'daily_playing_users',
						aggregate: 'sum',
						field: 'daily_playing_users',
					},
					{
						alias: 'play_time',
						aggregate: 'sum',
						field: 'play_time',
					},
					{
						alias: 'pre_play_time',
						aggregate: 'sum',
						field: 'pre_play_time',
					},
					{
						alias: 'video_ad_visible_time',
						aggregate: 'sum',
						field: 'video_ad_visible_time',
					},
					{
						alias: 'ingame_display_impressions',
						aggregate: 'sum',
						field: 'ingame_display_impressions',
					},
					{
						alias: 'gamebar_display_impressions',
						aggregate: 'sum',
						field: 'gamebar_display_impressions',
					},
					{
						alias: 'platform_display_impressions',
						aggregate: 'sum',
						field: 'platform_display_impressions',
					},
					{
						alias: 'preroll_video_impressions',
						aggregate: 'sum',
						field: 'preroll_video_impressions',
					},
					{
						alias: 'midroll_video_impressions',
						aggregate: 'sum',
						field: 'midroll_video_impressions',
					},
					{
						alias: 'rewarded_video_impressions',
						aggregate: 'sum',
						field: 'rewarded_video_impressions',
					},
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
						['p4d_game_id', 'in', gameIds],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
					],
				},
				group: [
					'date',
					'p4d_game_id',
					'team_id',
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getGamesOverviewTrendForTeam = createAuthorizedApiEpic(
	'data/get_games_overview_trend_for_team',
	(callApi, { gameIds, from, to, teamId, currency }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_games_overview',
				select: [
					{
						field: 'date',
					},
					{
						field: 'p4d_game_id',
					},
					{
						field: 'team_id',
					},
					{
						alias: 'gameplays',
						aggregate: 'sum',
						field: 'gameplays',
					},
					{
						alias: 'developer_earnings',
						aggregate: 'sum',
						field: currency === 'usd' ? 'developer_earnings_usd' : 'developer_earnings_eur',
					},
					{
						alias: 'daily_active_users',
						aggregate: 'sum',
						field: 'daily_active_users',
					},
					{
						alias: 'daily_playing_users',
						aggregate: 'sum',
						field: 'daily_playing_users',
					},
					{
						alias: 'play_time',
						aggregate: 'sum',
						field: 'play_time',
					},
					{
						alias: 'pre_play_time',
						aggregate: 'sum',
						field: 'pre_play_time',
					},
					{
						alias: 'video_ad_visible_time',
						aggregate: 'sum',
						field: 'video_ad_visible_time',
					},
					{
						alias: 'ingame_display_impressions',
						aggregate: 'sum',
						field: 'ingame_display_impressions',
					},
					{
						alias: 'gamebar_display_impressions',
						aggregate: 'sum',
						field: 'gamebar_display_impressions',
					},
					{
						alias: 'platform_display_impressions',
						aggregate: 'sum',
						field: 'platform_display_impressions',
					},
					{
						alias: 'preroll_video_impressions',
						aggregate: 'sum',
						field: 'preroll_video_impressions',
					},
					{
						alias: 'midroll_video_impressions',
						aggregate: 'sum',
						field: 'midroll_video_impressions',
					},
					{
						alias: 'rewarded_video_impressions',
						aggregate: 'sum',
						field: 'rewarded_video_impressions',
					},
					// We don't use these but we might sort or filter on them
					{
						alias: 'title',
						aggregate: 'max',
						field: 'pokifordevs_games.title',
					},
				],
				where: {
					expressions: [
						['team_id', '==', teamId],
						gameIds.length > 0 ? ['p4d_game_id', 'in', gameIds] : undefined,
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
					],
				},
				group: [
					'date',
					'p4d_game_id',
					'team_id',
				],
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getGamesOverviewFull = createAuthorizedApiEpic(
	'data/get_games_overview_full',
	(callApi, { from, to, page = 1, perPage = 10000, sortField, sortDirection, filters, currency, deviceCategories }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_games_overview',
				select: [
					{
						alias: '_release_status',
						aggregate: 'max',
						field: 'dbt_p4d_meta_game.release_status',
					},
					{
						alias: 'release_status_changed_at',
						aggregate: 'max',
						field: 'dbt_p4d_meta_game.release_status_changed_at',
					},
					{
						alias: 'num_domains_live',
						aggregate: 'max',
						field: 'dbt_p4d_meta_game.num_domains_live',
					},
					{
						alias: 'p4d_game_id',
						field: 'dbt_p4d_games_overview.p4d_game_id',
					},
					{
						field: 'team_id',
					},
					{
						alias: 'conversion_to_play',
						formula: measures.conversion_to_play.formula,
					},
					{
						alias: 'time_spent_per_dau',
						formula: measures.time_spent_per_dau.formula,
					},
					{
						alias: 'ads_per_dau',
						formula: measures.ads_per_dau.formula,
					},
					{
						alias: 'gameplays_per_day',
						formula: measures.gameplays_per_day.formula,
					},
					{
						alias: 'gameplays_total',
						aggregate: 'sum',
						field: 'gameplays',
					},
					{
						alias: 'developer_earnings_per_day',
						formula: currency === 'usd' ? measures.developer_earnings_usd_per_day.formula : measures.developer_earnings_eur_per_day.formula,
					},
					{
						aggregate: 'sum',
						alias: 'developer_earnings',
						field: currency === 'usd' ? 'developer_earnings_usd' : 'developer_earnings_eur',
					},
					// We don't use these but we might sort or filter on them
					{
						alias: 'title',
						aggregate: 'max',
						field: 'pokifordevs_games.title',
					},
					{
						alias: 'engine',
						aggregate: 'max',
						field: 'pokifordevs_games.engine',
					},
					{
						alias: 'name',
						aggregate: 'max',
						field: 'pokifordevs_teams.name',
					},
					{
						alias: 'customer_segment',
						aggregate: 'max',
						field: 'pokifordevs_teams.customer_segment',
					},
				],
				group: [
					'dbt_p4d_games_overview.p4d_game_id',
					'team_id',
				],
				order: [
					{
						field: sortField,
						direction: sortDirection > 0 ? 'asc' : 'desc',
					},
				],
				where: {
					expressions: [
						...filtersToExpressions(filters),
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
					],
				},
				offset: (page - 1) * perPage,
				limit: perPage,
				include: {
					p4d_game_id: {
						type: 'games',
					},
					team_id: {
						type: 'teams',
					},
				},
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getGamesOverviewForTeam = createAuthorizedApiEpic(
	'data/get_games_overview_for_team',
	(callApi, { from, to, page = 1, perPage = 10000, sortField, sortDirection, teamId, currency, deviceCategories }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_games_overview',
				select: [
					{
						field: 'p4d_game_id',
					},
					{
						alias: 'release_status',
						aggregate: 'argMax',
						val: 'date',
						field: 'release_status',
					},
					{
						alias: 'conversion_to_play',
						formula: measures.conversion_to_play.formula,
					},
					{
						alias: 'time_spent_per_dau',
						formula: measures.time_spent_per_dau.formula,
					},
					{
						alias: 'ads_per_dau',
						formula: measures.ads_per_dau.formula,
					},
					{
						alias: 'gameplays_per_day',
						formula: measures.gameplays_per_day.formula,
					},
					{
						alias: 'gameplays_total',
						aggregate: 'sum',
						field: 'gameplays',
					},
					{
						alias: 'developer_earnings_per_day',
						formula: currency === 'usd' ? measures.developer_earnings_usd_per_day.formula : measures.developer_earnings_eur_per_day.formula,
					},
					{
						aggregate: 'sum',
						alias: 'developer_earnings',
						field: currency === 'usd' ? 'developer_earnings_usd' : 'developer_earnings_eur',
					},
					// We don't use these but we might sort or filter on them
					{
						alias: 'title',
						aggregate: 'max',
						field: 'pokifordevs_games.title',
					},
				],
				group: [
					'p4d_game_id',
				],
				order: [
					{
						field: sortField,
						direction: sortDirection > 0 ? 'asc' : 'desc',
					},
				],
				where: {
					expressions: [
						['team_id', '==', teamId],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						deviceCategories?.length > 0 ? ['device_category', 'in', deviceCategories] : undefined,
					],
				},
				offset: (page - 1) * perPage,
				limit: perPage,
				include: {
					p4d_game_id: {
						type: 'games',
					},
				},
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getTeamsOverviewTeams = createAuthorizedApiEpic(
	'data/get_games_overview_teams',
	(callApi, { from, to }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_teams_overview',
				select: [
					{
						field: 'team_id',
					},
					{
						field: 'pokifordevs_teams.name',
					},
				],
				where: {
					expressions: [
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						['code', '!=', ''], // Filter out teams that have been deleted but are still in dbt.
					],
				},
				group: [
					'team_id',
					'name',
				],
				include: {
					team_id: {
						type: 'teams',
					},
				},
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getGamesOverviewGames = createAuthorizedApiEpic(
	'data/get_games_overview_games',
	(callApi, { teamId }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_games_overview',
				select: [
					{
						field: 'p4d_game_id',
					},
					{
						alias: 'title',
						aggregate: 'max',
						field: 'pokifordevs_games.title',
					},
				],
				group: [
					'p4d_game_id',
				],
				where: {
					expressions: [
						teamId ? ['team_id', '==', teamId] : undefined,
					],
				},
				limit: 10000000, // The default limit on the backend is 10000, but we want all games here, so we set it to a high number.
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getTeamsOverviewFull = createAuthorizedApiEpic(
	'data/get_teams_overview_full',
	(callApi, { from, to, page = 1, perPage = 999999999999, sortField, sortDirection, filters }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_teams_overview',
				select: [
					{
						field: 'pokifordevs_teams.id',
						alias: 'team_id',
					},
					{
						alias: 'name',
						function: {
							name: 'lower',
							args: [
								{
									field: 'pokifordevs_teams.name',
								},
							],
						},
					},
					{
						field: 'pokifordevs_teams.customer_segment',
					},
					{
						field: 'pokifordevs_teams.created_at',
					},
					{
						field: 'pokifordevs_teams.flags',
					},
					{
						aggregate: 'sum',
						field: 'gameplays',
						alias: 'gameplays',
					},
					{
						aggregate: 'sum',
						field: 'developer_earnings_eur',
						alias: 'developer_earnings_eur',
					},
					{
						aggregate: 'sum',
						field: 'developer_earnings_usd',
					},
					{
						// This field is only used for sorting, it isn't shown.
						// So a max aggregate is good enough here.
						aggregate: 'max',
						field: 'games_count',
						alias: 'games_count',
					},
				],
				group: [
					'team_id',
					'pokifordevs_teams.flags',
					'pokifordevs_teams.name',
					'pokifordevs_teams.customer_segment',
					'pokifordevs_teams.created_at',
				],
				order: [
					{
						field: sortField,
						direction: sortDirection > 0 ? 'asc' : 'desc',
					},
				],
				where: {
					expressions: [
						...filtersToExpressions(filters),
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
						['code', '!=', ''], // Filter out teams that have been deleted but are still in dbt.
					],
				},
				include: {
					team_id: {
						type: 'teams',
					},
				},
				offset: (page - 1) * perPage,
				limit: perPage,
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

export const getTeamsOverviewTrend = createAuthorizedApiEpic(
	'data/get_teams_overview_trend',
	(callApi, { teamIds, from, to }) => (
		callApi({
			url: getApiUrl('devs', '_data'),
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: {
				from: 'dbt_p4d_games_overview',
				select: [
					{
						field: 'date',
					},
					{
						field: 'team_id',
					},
					{
						aggregate: 'sum',
						field: 'gameplays',
					},
					{
						aggregate: 'sum',
						field: 'developer_earnings_eur',
					},
					{
						aggregate: 'sum',
						field: 'developer_earnings_usd',
					},
				],
				group: [
					'date',
					'team_id',
				],
				where: {
					expressions: [
						['team_id', 'in', teamIds],
						['date', '>=', formatDateAsString(from)],
						['date', '<=', formatDateAsString(to)],
					],
				},
			},
		})
	),
	null,
	{ cacheSeconds: clickhouseDataCacheSeconds },
);

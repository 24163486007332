{
    "denimBlue": "#002B50",
    "green1": "#15C8D1",
    "green2": "#17D7D8",
    "green3": "#18E9DA",
    "green4": "#17F1D8",
    "green5": "#3CF7DC",
    "green6": "#4DFFE1",
    "green7": "#83FFE7",
    "green8": "#A4FFEE",
    "green9": "#D1FFF6",
    "grey1": "#3A4355",
    "grey3": "#5D6B84",
    "grey5": "#BAC9DE",
    "grey7": "#F0F5FC",
    "grey9": "#F9FBFF",
    "pokiBlue": "#009CFF",
    "pokiHover": "#007ECC",
    "pureWhite": "#FFFFFF",
    "purple1": "#C977E8",
    "purple2": "#D490F2",
    "purple3": "#DA9EF3",
    "purple4": "#DCA7F2",
    "purple5": "#E0AEF5",
    "purple6": "#E1B5F7",
    "purple7": "#EAC0FC",
    "purple8": "#EBCCFF",
    "rose1": "#FF7690",
    "rose2": "#FF8AA6",
    "rose3": "#FF99B1",
    "rose4": "#FFA4BA",
    "rose5": "#FFA9BE",
    "rose6": "#FFAEC5",
    "rose7": "#FFC2D6",
    "rose8": "#FFC9DD",
    "yellow1": "#FF9E00",
    "yellow2": "#FFAF00",
    "yellow3": "#FFC500",
    "yellow4": "#FFD100",
    "yellow5": "#FFDC00",
    "yellow6": "#FFE200",
    "yellow7": "#FAEB59",
    "yellow8": "#FFED85"
}
import React from 'react';
import { GridRows as VisxGridRows, GridColumns as VisxGridColumns } from '@visx/grid';

import { useSelectTheme } from 'app/src/selectors/session';

export const GridRows = ({ scale, width }) => {
	const theme = useSelectTheme();

	return (
		<VisxGridRows scale={scale} width={width} stroke={theme.grey7} />
	);
};

export const GridColumns = ({ scale, height }) => {
	const theme = useSelectTheme();

	return (
		<VisxGridColumns scale={scale} height={height} stroke={theme.grey7} />
	);
};

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ignoreElements, tap } from 'rxjs';
import { useSelectApiStatus } from '@poki/rx-api';
import { v4 as uuid } from 'uuid';
import moment from 'moment';

import { createPlayerFeedbackSummary, getPlayerFeedbackSummary } from 'app/src/epics/game';
import { useSelectPlayerFeedbackSummary } from 'app/src/selectors/game';

import TextAreaInput from 'app/src/components/input/TextAreaInput';
import Button from 'app/src/components/ui/Button';
import Card from 'app/src/components/ui/Card';
import DateRangeInput from 'app/src/components/input/DateRangeInput';
import MultiSelectInput from 'app/src/components/input/MultiSelectInput';

import { dayMonthYearFormat, earliestMomentInP4D } from 'shared/vars';
import _ from 'shared/copy';

const PLAYER_FEEDBACK_INTERVAL = 1000; // 1 second.
const DOTS_INTERVAL = 500; // 0.5 seconds.

const PLAYER_FEEDBACK_TYPES = [
	{ value: 'thumbs_up', desc: _`positive` },
	{ value: 'thumbs_down', desc: _`negative` },
	{ value: 'bugreport', desc: _`bugreport` },
];

const StyledTextAreaInput = styled(TextAreaInput)`
	max-width: 100%;
`;

const StyledPre = styled.pre`
	background: ${props => props.theme.grey7};
	margin-bottom: 16px;
`;

const StyledResponse = styled.pre`
	white-space: pre-wrap;
	word-wrap: break-word;
`;

const Buttons = styled.div`
	margin-bottom: 20px;
	padding-bottom: 20px;
	display: flex;
	justify-content: space-between;
`;

const PlayerFeedbackSummaryExperiment = props => {
	const { game } = props;

	const dispatch = useDispatch();
	const summary = useSelectPlayerFeedbackSummary(game.id);
	const createPlayerFeedbackSummaryStatus = useSelectApiStatus(createPlayerFeedbackSummary.id);

	const [prompt, setPrompt] = useState(`Summarize the following player feedback of my web game '${game.title}' which is live on Poki.\nGive me 5 positive points, 5 negative points and the 5 most commonly reported bugs with how often they are mentioned.\n\nGenerate a plain text response, no markdown formatting!\n`);
	const [awaitingResponse, setAwaitingResponse] = useState(false);
	const [id, setId] = useState('');
	const [dots, setDots] = useState('.');
	const [startDate, setStartDate] = useState(moment.utc().subtract(365, 'days'));
	const [endDate, setEndDate] = useState(moment.utc().subtract(1, 'day'));
	const [types, setTypes] = useState(PLAYER_FEEDBACK_TYPES.map(({ desc }) => desc));

	const haveResponse = summary && (summary.response !== '' && summary.response !== 'processing...');

	const handleSubmit = () => {
		const newId = uuid();

		setId(newId);

		// Map types back to values.
		const t = types.reduce((acc, a) => {
			const { value } = PLAYER_FEEDBACK_TYPES.find(({ desc }) => desc === a);
			return acc.concat(value);
		}, []);

		dispatch(createPlayerFeedbackSummary.fetch(
			{ id: newId, gameId: game.id, prompt, startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD'), types: t },
			({ success$ }) => success$.pipe(
				tap(() => {
					setAwaitingResponse(true);
				}),
				ignoreElements(),
			),
		));
	};

	useEffect(() => {
		if (awaitingResponse) {
			// Since we have 3 backend pods running, without session stickiness,
			// only 1 in 3 requests will return us a valid response.
			// Just keep polling every second until we're lucky.
			const updateInterval = setInterval(() => {
				dispatch(getPlayerFeedbackSummary.fetch({ id, gameId: game.id }));
			}, PLAYER_FEEDBACK_INTERVAL);

			const dotsInterval = setInterval(() => {
				setDots(oldDots => {
					if (oldDots.length === 3) {
						return '.';
					}
					return `${oldDots}.`;
				});
			}, DOTS_INTERVAL);

			return () => {
				clearInterval(updateInterval);
				clearInterval(dotsInterval);
			};
		}
	}, [awaitingResponse]);

	useEffect(() => {
		if (awaitingResponse && haveResponse) {
			setAwaitingResponse(false);
		}
	}, [awaitingResponse, haveResponse]);

	return (
		<Card
			title="Summarize Player Feedback Experiment"
		>
			<DateRangeInput
				label="Date Range"
				minDate={earliestMomentInP4D}
				maxDate={moment.utc().subtract(1, 'day')}
				value={[startDate.format(dayMonthYearFormat), endDate.format(dayMonthYearFormat)]}
				valueSetter={([_startDate, _endDate]) => {
					setStartDate(_startDate);
					setEndDate(_endDate);
				}}
				format={dayMonthYearFormat}
				prefix={`${_`dateRange`}:`}
				light
			/>
			<MultiSelectInput
				label="Type"
				placeholder="- Select an audience -"
				valueSetter={setTypes}
				value={types}
				values={PLAYER_FEEDBACK_TYPES}
			/>
			<StyledTextAreaInput
				label="Prompt"
				name="prompt"
				value={prompt}
				valueSetter={setPrompt}
			/>
			Up to 20000 of the latest player feedback will be appended to the prompt in the following way:
			<StyledPre>{'This is the player feedback separated by ---:\n```\n2025-01-30: newest player feedback\n---\n2025-01-29: etc...\n```'}</StyledPre>
			<Buttons>
				<Button disabled={awaitingResponse || createPlayerFeedbackSummaryStatus.pending} onClick={handleSubmit}>Generate Response</Button>
			</Buttons>
			{(awaitingResponse || haveResponse) && (
				<Card
					title="Result"
				>
					<StyledResponse>
						{(!awaitingResponse && haveResponse) ? summary.response : `processing${dots}`}
					</StyledResponse>
				</Card>
			)}
		</Card>
	);
};

export default PlayerFeedbackSummaryExperiment;

import React from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { useSelectPermissions } from 'app/src/selectors/user';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import { openModal } from 'app/src/actions/client';
import checkPermissions from 'app/src/utils/checkPermissions';

import TrashIcon from 'shared/designTokens/icons/ui/small/TrashIcon';

import GameGeneralSettingsModule from 'app/src/components/modules/GameGeneralSettingsModule';
import GameBeaconSnippetModule from 'app/src/components/modules/GameBeaconSnippetModule';
import GameUploadTokenModule from 'app/src/components/modules/GameUploadTokenModule';
import LinkStyledButton from 'app/src/components/ui/LinkStyledButton';
import GridContainer from 'app/src/components/ui/GridContainer';
import Card from 'app/src/components/ui/Card';

import _ from 'shared/copy';

const StyledLinkButton = styled(LinkStyledButton)`
	display: flex;
	align-items: center;
	width: max-content;
	font-weight: 700;
	gap: 8px;

	svg {
		[fill] {
			fill: ${props => props.theme.rose1};
		}
	}

	&:hover {
		svg {
			[fill] {
				fill: ${props => props.theme.rose3};
			}
		}
	}
`;

const GameSettingsSubPage = props => {
	const { game } = props;

	const dispatch = useDispatch();

	const activeTeam = useSelectActiveTeam();
	const permissions = useSelectPermissions();

	const hasContentId = game.content_metadata && !!game.content_metadata.content_game_id;
	const isGameReleased = game.content_metadata && game.content_metadata.release_status !== 'not-released' && game.content_metadata.release_status !== 'no-link-release';

	const openRemoveGameModal = () => dispatch(openModal(
		{
			key: 'confirm-remove-game',
			data: { gameId: game.id, teamCode: game.team.code },
		},
	));

	return (
		<GridContainer cols={1}>
			<Helmet key={`GameSettingsSubPage-${game.id}-${activeTeam.code}`}>
				<title>Settings - {game.title} - {activeTeam.name} - Poki for Developers</title>
			</Helmet>
			<GameGeneralSettingsModule game={game} />
			{checkPermissions(permissions, [['can_generate_upload_token']]) && <GameUploadTokenModule game={game} />}
			{activeTeam.verified && checkPermissions(permissions, [['can_edit_owned_versions']]) && <GameBeaconSnippetModule game={game} />}
			{(!hasContentId || !isGameReleased) && (
				<Card>
					<StyledLinkButton negative onClick={openRemoveGameModal}>
						<TrashIcon />
						{_`deleteGameFromPoki`}
					</StyledLinkButton>
				</Card>
			)}
		</GridContainer>
	);
};

export default GameSettingsSubPage;

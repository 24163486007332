import React from 'react';
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend';
import styled from 'styled-components';

const LEGEND_GLYPH_SIZE = 15;

const LegendDemo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	height: auto;
	padding: 16px 24px;
	font-size: 12px;
	font-weight: bold;
	color: ${props => props.theme.grey3};
`;

const LegendItemWrapper = styled.div`
	display: flex;
	gap: 16px;
`;

const ChartLegend = ({ scale }) => (
	<LegendDemo>
		<LegendOrdinal scale={scale}>
			{labels => (
				<LegendItemWrapper>
					{labels.map(label => (
						<LegendItem key={`legend-quantile-${label.datum}`}>
							<svg width={LEGEND_GLYPH_SIZE} height={LEGEND_GLYPH_SIZE}>
								<circle fill={label.value} r={LEGEND_GLYPH_SIZE / 3} cx={LEGEND_GLYPH_SIZE / 2} cy={LEGEND_GLYPH_SIZE / 2} />
							</svg>
							<LegendLabel align="left" margin="0 4px">
								{label.text}
							</LegendLabel>
						</LegendItem>
					))}
				</LegendItemWrapper>
			)}
		</LegendOrdinal>
	</LegendDemo>
);

export default ChartLegend;

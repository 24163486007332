import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import FeedbackIcon from 'shared/designTokens/icons/ui/small/FeedbackIcon';
import ExclamationIcon from 'shared/designTokens/icons/ui/small/ExclamationIcon';

import { useSelectActiveTeam, useSelectReviewPendingForGame } from 'app/src/selectors/team';
import { getLatestVersionReview } from 'app/src/utils/versions';
import { useSelectPermissions, useSelectUser } from 'app/src/selectors/user';
import { openModal } from 'app/src/actions/client';
import getReviewStatusText from 'app/src/utils/getReviewStatusText';
import getTimeSince from 'app/src/utils/getTimeSince';
import checkPermissions from 'app/src/utils/checkPermissions';

import Button from 'app/src/components/ui/Button';
import IconButton from 'app/src/components/ui/IconButton';

import { dayMonthYearTimeFormat } from 'shared/vars';
import _ from 'shared/copy';
import Tooltip from 'shared/components/Tooltip';

const ReviewStatusContainer = styled.div`
	display: flex;
`;

const ReviewStatusContent = styled.div`
	display: flex;
	flex-direction: column;

	&:not(:first-child) {
		margin-left: 10px;
	}
`;

const ReviewStatusText = styled.div`
	font-weight: bold;

	${props => (props.$status === 'pending' || props.$status === 'uploading' ? `
	color: ${props.theme.pokiBlue};
	` : props.$status === 'approved' || props.$status === 'done' ? `
	color: ${props.theme.green1};
	` : props.$status === 'rejected' ? `
	color: ${props.theme.rose5};
	` : props.$status === 'closed' ? `
	color: ${props.theme.grey3};
	` : props.$status === 'error' ? `
	color: ${props.theme.grey5}
	` : '')}
`;

const ReviewStatusTime = styled.div`
	font-size: 12px;
	line-height: 20px;
	color: ${props => props.theme.grey3};
`;

const VersionReviewStatus = props => {
	const { game, item = false } = props;
	const { reviews = [], state, created_at } = item;

	const dispatch = useDispatch();
	const permissions = useSelectPermissions();
	const user = useSelectUser();
	const activeTeam = useSelectActiveTeam();
	const reviewPendingForGame = useSelectReviewPendingForGame(activeTeam.code);

	const [hasReviewPending, setHasReviewPending] = useState(false);

	const latestReview = getLatestVersionReview(reviews);
	const hasPermissions = checkPermissions(permissions, [['can_create_owned_reviews', 'can_read_owned_reviews']]);
	const isAdmin = user.role === 'admin';

	const handleCreateReviewRequest = () => {
		dispatch(openModal({ key: 'create-review-request', data: { game, version: item } }));
	};

	const handleViewReviewRequest = () => {
		dispatch(openModal({ key: 'view-review-request', data: { game, version: item, review: latestReview } }));
	};

	useEffect(() => {
		if (isAdmin) return;

		if (reviewPendingForGame && reviewPendingForGame !== game.id) {
			setHasReviewPending(true);
		} else {
			setHasReviewPending(false);
		}
	}, [game.id, reviewPendingForGame]);

	const ButtonComponent = (
		<Button
			onClick={handleCreateReviewRequest}
			disabled={state !== 'done' || hasReviewPending}
		>
			{state !== 'done' ? _`processing` : _`requestReview`}
		</Button>
	);

	if (hasPermissions && game.approved && reviews.length === 0) {
		return (
			state !== 'error' ? (
				hasReviewPending ? (
					<Tooltip
						placement="top"
						arrow
						content={_`gameReviewInProgress`}
						maxWidth={300}
					>
						{ButtonComponent}
					</Tooltip>
				) : ButtonComponent
			) : (
				<ReviewStatusContainer>
					<IconButton
						icon={ExclamationIcon}
						title={_`viewRequest`}
						disabled
					/>
					<ReviewStatusContent>
						<ReviewStatusText $status={state}>{getReviewStatusText(state)}</ReviewStatusText>
						<ReviewStatusTime title={moment(created_at * 1000).format(dayMonthYearTimeFormat)}>
							{_`timeAgo${{ timeSince: getTimeSince(created_at * 1000) }}`}
						</ReviewStatusTime>
					</ReviewStatusContent>
				</ReviewStatusContainer>
			)
		);
	}

	return (
		<ReviewStatusContainer>
			{!game.approved ? (
				<ReviewStatusText $status={state}>{getReviewStatusText(state)}</ReviewStatusText>
			) : hasPermissions && (
				<>
					<IconButton
						icon={FeedbackIcon}
						title={_`viewRequest`}
						onClick={handleViewReviewRequest}
					/>
					<ReviewStatusContent>
						<ReviewStatusText $status={latestReview.status}>
							{getReviewStatusText(latestReview.status)}
						</ReviewStatusText>
						{latestReview.status !== 'closed' && (
							<ReviewStatusTime title={moment((latestReview.status === 'pending' ? latestReview.created_at : latestReview.report_submitted_at) * 1000).format(dayMonthYearTimeFormat)}>
								{_`timeAgo${{ timeSince: getTimeSince((latestReview.status === 'pending' ? latestReview.created_at : latestReview.report_submitted_at) * 1000) }}`}
							</ReviewStatusTime>
						)}
					</ReviewStatusContent>
				</>
			)}
		</ReviewStatusContainer>
	);
};

export default VersionReviewStatus;

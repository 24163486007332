import React, { useContext } from 'react';
import { Tooltip } from '@visx/tooltip';

import { ChartContext } from 'app/src/components/ui/chart/ChartContainer';
import styled from 'styled-components';

const ChartTooltipContainer = styled(Tooltip)`
	pointer-events: none;
	position: absolute;
	top: ${props => props.top}px;
	left: ${props => props.left}px;

	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	padding: 16px;

	background: ${props => props.theme.pureWhite};
	border-radius: 8px;
	border: 1px solid ${props => props.theme.grey7};
	box-shadow: ${props => props.theme.boxShadowSmall};
	width: max-content;
	
`;

const ChartTooltipTitle = styled.div`
	font-size: 16px;
	font-weight: bold;
	color: ${props => props.theme.denimBlue};
`;

const ChartTooltipContent = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
`;

const ChartTooltipLegendRange = styled.div`
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: ${props => props.$color};
`;

const ChartTooltipValue = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 16px;

	font-size: 14px;
	font-weight: bold;
	color: ${props => props.theme.grey3};
`;

const ChartTooltip = () => {
	const { data, legendRange, tooltipData, tooltipLeft, tooltipTop } = useContext(ChartContext);

	// TODO temporary solution tooltip that is currently being used in the Player Fit Test only.

	const { bar, groupIndex } = tooltipData;

	return (
		<ChartTooltipContainer top={tooltipTop} left={tooltipLeft} unstyled>
			<ChartTooltipTitle>Range: {data[groupIndex].range}</ChartTooltipTitle>
			<ChartTooltipContent>
				<ChartTooltipLegendRange $color={legendRange[bar.index]} />
				<ChartTooltipValue>
					<span>Gameplays</span>
					<span>{bar.value}</span>
				</ChartTooltipValue>
			</ChartTooltipContent>
		</ChartTooltipContainer>
	);
};

export default ChartTooltip;

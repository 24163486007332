import { BehaviorSubject, tap, ignoreElements, mergeMap } from 'rxjs';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import { combineEpics } from 'redux-observable';
import { createApiReducer } from '@poki/rx-api';

import history from 'app/history';
import additionalPayments from 'app/src/reducers/additionalPayments';
import data from 'app/src/reducers/data';
import client from 'app/src/reducers/client';
import domains from 'app/src/reducers/domains';
import effects from 'app/src/reducers/effects';
import invoice from 'app/src/reducers/invoice';
import game from 'app/src/reducers/game';
import search from 'app/src/reducers/search';
import persistedSession from 'app/src/reducers/persistedSession';
import session from 'app/src/reducers/session';
import team from 'app/src/reducers/team';
import user from 'app/src/reducers/user';
import review from 'app/src/reducers/review';
import playtests from 'app/src/reducers/playtests';
import playerfit from 'app/src/reducers/playerfit';
import guardian from 'app/src/reducers/guardian';
import epicMiddleware from 'app/src/middlewares/epicMiddleware';
import { locationChanged, openModal } from 'app/src/actions/client';
import { selectPreventPageLeave } from 'app/src/selectors/client';
import epics from 'app/src/epics';

const runId = `${performance ? performance.now() : new Date().getTime()}${Math.random()}`;

// https://redux-observable.js.org/docs/recipes/AddingNewEpicsAsynchronously.html
export const epicRoot$ = new BehaviorSubject(
	combineEpics(
		...(process.env.NODE_ENV === 'development' ? ([
			// Development epics
			action$ => (
				action$.pipe(
					tap(action => console.info('🚀', { ...action })),
					ignoreElements(),
				)
			),
		]) : []),
		...epics,
	),
);

export const initializeStore = (({ persistedStates = [] } = {}) => {
	const reducers = combineReducers({
		rxapi: createApiReducer('rxapi'),
		additionalPayments,
		data,
		client,
		domains,
		effects,
		invoice,
		game,
		search,
		persistedSession,
		session,
		team,
		user,
		review,
		playtests,
		playerfit,
		guardian,
	});

	const store = createStore(
		// Root reducer
		(state, action) => {
			// On top of our reducers, we have this persist logic.
			// This ensures that persisted state changes are applied to other tabs as well
			if (action.type === 'PERSIST') {
				state[action.key] = action.value;
			}

			return reducers(state, action);
		},
		// Retrieve persisted state
		persistedStates.reduce((acc, current) => {
			try {
				const storage = localStorage.getItem(`state/${current.key}`);
				if (storage) {
					acc[current.key] = {
						...current.initialState,
						...JSON.parse(storage).state,
					};

					// Uncomment this to reset the self-service flow state to its initial state for testing.
					// acc[current.key].gameChecklistStatus = {};
				}
			} catch { }
			return acc;
		}, {}),
		// Apply Middleware
		applyMiddleware(
			epicMiddleware,
		),
	);

	// Prevent history changes if necessary
	const ogPush = history.push;
	history.push = (...args) => {
		if (selectPreventPageLeave(store.getState())) {
			store.dispatch(openModal({ key: 'leave-page-confirmation', data: { path: args[0] } }));
			return;
		}
		ogPush(...args);
	};

	history.listen(change => store.dispatch(locationChanged({ payload: { ...change } })));

	// Persist state
	store.subscribe(() => {
		// Don't place any localStorage entry if we are logging or logged in.
		const sessionState = store.getState().persistedSession;
		if (!sessionState.hasPreviouslySignedIn && !sessionState.previousRefreshToken && !sessionState.accessToken && !sessionState.refreshToken) {
			return;
		}

		persistedStates.forEach(state => {
			try {
				localStorage.setItem(`state/${state.key}`, JSON.stringify({ runId, state: store.getState()[state.key] }));
			} catch { }
		});
	});

	// If state changed in another tab, sync ourselves with it
	window.addEventListener('storage', evt => { // Note: storage events are not fired on the tab where the storage changed (except for Safari)
		// Only sync if we are in focus
		if (!document.hasFocus()) return;

		let { key } = evt;

		if (!key || !key.includes('state')) return;

		key = key.replace(/^(state\/)/, '');

		if (persistedStates.includes(key) && evt.oldValue !== evt.newValue) {
			const parsed = JSON.parse(evt.newValue);

			// We have to keep track ourselves whether this storage event came from another tab
			// because it's broken in Safari, causing a lot of issues
			if (parsed.runId === runId) return; // Exit if we are in the same tab that this event originated from

			store.dispatch({ type: 'PERSIST', key, value: parsed.state });
		}
	});

	// Sync this tab with the state when it gets focus
	window.addEventListener('focus', () => {
		persistedStates.forEach(key => {
			try {
				const value = localStorage.getItem(`state/${key}`);

				if (value) {
					const parsed = JSON.parse(value);
					if (store.getState()[key] !== parsed.state) {
						store.dispatch({ type: 'PERSIST', key, value: parsed.state });
					}
				}
			} catch { }
		});
	});

	// https://redux-observable.js.org/docs/recipes/AddingNewEpicsAsynchronously.html
	const rootEpic = (action$, state$) => epicRoot$.pipe(
		mergeMap(epic => epic(action$, state$)),
	);

	epicMiddleware.run(rootEpic);

	return store;
});

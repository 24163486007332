import React, { useContext } from 'react';
import { BarGroup } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { localPoint } from '@visx/event';

import { useSelectTheme } from 'app/src/selectors/session';

import { ChartContext } from 'app/src/components/ui/chart/ChartContainer';
import { XAxis, YAxis } from 'app/src/components/ui/chart/Axes';
import { GridRows } from 'app/src/components/ui/chart/GridLines';

import { isMobile } from 'shared/vars';

const TOOLTIP_OFFSET = 100;

const BarGroupChart = ({ xKey }) => {
	const { data, width, height, margins, showTooltip, hideTooltip } = useContext(ChartContext);
	const theme = useSelectTheme();

	if (!data || data.length === 0) {
		return null;
	}

	const keys = Object.keys(data[0]).filter(d => d !== 'range') || [];

	// bounds
	const xMax = width - margins.left - margins.right;
	const yMax = height - margins.top - margins.bottom;

	const getXKey = d => d[xKey];
	const maxYValue = Math.max(...data.flatMap(d => [...keys].map(key => d[key])));

	const xScale = scaleBand({
		domain: data.map(getXKey),
		padding: 0.2,
	});

	const x0Scale = scaleBand({
		domain: keys,
		padding: 0.1,
	});

	const yScale = scaleLinear({
		domain: [0, maxYValue],
		range: [yMax, 0],
	});

	const colorScale = scaleOrdinal({
		domain: keys,
		range: [theme.dataBlue5, theme.dataBlue3],
	});

	// update scale output dimensions
	xScale.rangeRound([0, xMax]);
	x0Scale.rangeRound([0, xScale.bandwidth()]);
	yScale.nice();

	return (
		<svg width={width} height={height}>
			<Group top={margins.top} left={margins.left}>
				<GridRows scale={yScale} width={xMax} />
				<YAxis scale={yScale} hideAxisLine hideTicks />
				<XAxis scale={xScale} top={yMax} hideAxisLine hideTicks numTicks={isMobile ? 3 : 5} />
				<BarGroup
					data={data}
					keys={keys}
					height={yMax}
					x0={getXKey}
					x0Scale={xScale}
					x1Scale={x0Scale}
					yScale={yScale}
					color={colorScale}
				>
					{barGroups => (
						barGroups.map(barGroup => (
							<Group key={`bar-group-${barGroup.index}-${barGroup.x0}`} left={barGroup.x0}>
								{barGroup.bars.map(bar => (
									<rect
										key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
										x={bar.x}
										y={bar.y}
										width={bar.width}
										height={bar.height}
										fill={bar.color}
										onMouseLeave={() => hideTooltip()}
										onMouseMove={event => {
											const point = localPoint(event);
											const isGrouped = barGroup.bars.length > 1;

											showTooltip({
												tooltipData: {
													bar,
													groupIndex: barGroup.index,
												},
												tooltipTop: isGrouped ? point.y + margins.top + TOOLTIP_OFFSET : point.y,
												tooltipLeft: point.x,
											});
										}}
									/>
								))}
							</Group>
						))
					)}
				</BarGroup>
			</Group>
		</svg>
	);
};

export default BarGroupChart;

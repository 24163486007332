const SSF_STAGES_ORDERED = [
	'versions_uploaded',
	'running_playtests',
	'running_player_fit_tests',
	'poki_web_fit_requested',
	'advised_to_continue_playtests',
	'poki_web_fit_eligible_but_needs_tweaks',
	'running_poki_web_fit_test',
	'poki_web_fit_score_to_send',
	'advised_to_continue_iterating',
	'share_positive_poki_web_fit_score',
];

export const checklistSteps = [
	{
		level: 'playtest',
		steps: [
			{ step: 1, requiredStage: 'versions_uploaded' },
			{ step: 2, requiredStage: 'versions_uploaded' },
			{ step: 3, requiredStage: 'versions_uploaded' },
			{ step: 4, requiredStage: 'running_playtests' },
		],
	},
	{
		level: 'playerfit',
		steps: [
			{ step: 1, requiredStage: 'running_player_fit_tests' },
		],
	},
	{
		level: 'webfit',
		steps: [
			{ step: 1, requiredStage: 'running_player_fit_tests' },
			{ step: 2, requiredStage: 'running_player_fit_tests' },
			{ step: 2, requiredStage: 'advised_to_continue_iterating' },
			{ step: 3, requiredStage: 'advised_to_continue_iterating' },
		],
	},
	{
		level: 'release-game',
		steps: [
			{ step: 1, requiredStage: 'share_positive_poki_web_fit_score' },
		],
	},
];

export const isSSFStageAfter = (stage, currentStage) => {
	const stageIndex = SSF_STAGES_ORDERED.indexOf(stage);
	const currentStageIndex = SSF_STAGES_ORDERED.indexOf(currentStage);

	return stageIndex > currentStageIndex;
};

export const showPlaytestMenuItem = (game, checklistStatus) => {
	const { id: gameId, self_service_stage: selfServiceStage, approved } = game;
	const { playtest } = checklistStatus[gameId] || {};

	if (approved) {
		return true;
	}

	// If the game is already in the running_playtests stage or after it, show the playtest menu item.
	if (selfServiceStage && isSSFStageAfter(selfServiceStage, 'versions_uploaded')) {
		return true;
	}

	return playtest?.[2]?.status === 'completed';
};

export const showPlayerFitMenuItem = (game, checklistStatus) => {
	const { id: gameId, self_service_stage: selfServiceStage, approved } = game;
	const { playtest } = checklistStatus[gameId] || {};

	if (approved) {
		return true;
	}

	// If the game is already in the running_player_fit_tests stage or after it, show the player fit menu item.
	if (selfServiceStage && isSSFStageAfter(selfServiceStage, 'running_playtests')) {
		return true;
	}

	return playtest?.[3]?.status === 'completed';
};

export const getCurrentLevel = (game, isGameJam, checklistStatus) => {
	const { self_service_stage: selfServiceStage } = game;

	if (!selfServiceStage) {
		return 0;
	}

	const isPlaytestCompleted = checklistStatus?.playtest?.[3]?.status === 'completed';
	const isPlayerFitCompleted = checklistStatus?.playerfit?.[0]?.status === 'completed';

	const stageLevels = {
		versions_uploaded: isPlayerFitCompleted ? 3 : (isPlaytestCompleted ? 2 : 1),
		running_playtests: isPlayerFitCompleted ? 3 : 2,
		running_player_fit_tests: 2,
		share_positive_poki_web_fit_score: 4,
		completed: 4,
	};

	let currentLevel = stageLevels[selfServiceStage] ?? 3;

	if (isGameJam && currentLevel > 2) {
		currentLevel = 2;
	}

	return currentLevel;
};

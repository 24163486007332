import React, { useState, useRef } from 'react';
import { fromEvent, EMPTY, tap, map, ignoreElements } from 'rxjs';
import { useSelectApiStatus } from '@poki/rx-api';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { patchGameVersion } from 'app/src/epics/game';
import { openToast } from 'app/src/actions/client';
import useEpic from 'app/src/hooks/useEpic';

import { isMobile, motionOut, motionSpeed02 } from 'shared/vars';
import _ from 'shared/copy';

const NameContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	${!isMobile ? `
	min-height: 48px;
	` : ''}
`;

const SubName = styled.div`
	font-size: 14px;
	line-height: 20px;
	color: ${props => props.theme.grey3};
`;

const LabelInput = styled.input`
	background: transparent;
	display: block;
	padding: 0 16px;
	line-height: 36px;
	height: 36px;
	width: 100%;
	border: 1px solid transparent;
	color: ${props => props.theme.denimBlue};
	font-size: 16px;
	transition: background ${motionSpeed02} ${motionOut}, color ${motionSpeed02} ${motionOut};
	border-radius: 8px;
	margin-left: -17px;
	margin-top: -6px;

	::placeholder {
		color: ${props => props.theme.grey3};
	}

	${props => (!props.disabled ? `
	&:hover,
	&:focus {
		border: 1px solid ${props.theme.grey5};
	}

	&:focus {
		outline: none;
		border-color: ${props.theme.pokiBlue};
		box-shadow: inset 0 0 0 2px ${props.theme.pokiBlue};
		background: ${props.theme.grey9};
	}
	` : '')}
`;

const LabelFieldset = styled.fieldset`
	position: relative;
	max-width: 500px;
	width: 100%;
`;

const VersionLabel = props => {
	const { item, readOnly = false, setHasAnyLabelFocus } = props;

	const dispatch = useDispatch();

	const inputRef = useRef();
	const hasFocusRef = useRef(false);

	const [label, setLabel] = useState(item.label);

	const patchGameVersionStatus = useSelectApiStatus(patchGameVersion.id);

	const name = label || item.filename;

	const handleFocus = () => {
		hasFocusRef.current = true;
	};

	const handleBlur = () => {
		if (!hasFocusRef.current) {
			return;
		}

		hasFocusRef.current = false;

		if (setHasAnyLabelFocus) setHasAnyLabelFocus(false);

		// Don't save if label didn't change
		if (item.label === label) {
			return;
		}

		dispatch(patchGameVersion.fetch({ gameId: item.game_id, versionId: item.id, data: { label } }, ({ success$ }) => (
			success$.pipe(
				tap(() => {
					// Shortcut for ensuring item is updated without having to re-retrieve it
					// usually would not recommend (who knows what the server does), but we're only changing one field and keeping
					// this part fast is worth the shortcut
					item.label = label;
				}),
				map(() => openToast({ body: _`toastVersionLabelSaved` })),
			)
		)));
	};

	// close shortcut
	useEpic(() => {
		if (!inputRef.current) {
			return EMPTY;
		}

		return fromEvent(inputRef.current, 'keydown').pipe(
			tap(e => {
				const { keyCode } = e;

				if (keyCode === 13) { // Enter - save
					handleBlur();
				} else if (keyCode === 27) { // Esc - cancel
					setLabel(item.label);
					inputRef.current.blur();
				}
			}),
			ignoreElements(),
		);
	}, [label, inputRef]);

	return (
		<NameContainer>
			{isMobile ? (
				<div>{name}</div>
			) : (
				<LabelFieldset>
					<LabelInput
						type="text"
						ref={inputRef}
						disabled={readOnly || patchGameVersionStatus.pending}
						name={`version-label-${item.id}`}
						placeholder={_`setACustomLabel`}
						value={hasFocusRef.current ? label : name}
						onBlur={handleBlur}
						onFocus={handleFocus}
						onChange={evt => setLabel(evt.target.value)}
						maxLength={256}
					/>
				</LabelFieldset>
			)}
			<SubName>Filename: {item.filename}</SubName>
		</NameContainer>
	);
};

export default VersionLabel;

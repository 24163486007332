import React from 'react';
import styled from 'styled-components';

import { InvalidPropError } from 'app/src/errors';

import Notification from 'app/src/components/ui/Notification';

import _ from 'shared/copy';

export const LabelTypes = {
	NEW: 0,
	LABS: 1,
	NUMBER: 2,
};

const Container = styled.div`
	position: relative;
	padding: 1px 6px 3px;
	border-radius: 4px;

	font-family: Torus;
	font-size: 12px;
	line-height: 16px;
	text-transform: uppercase;

	${props => {
		if (props.type === LabelTypes.NEW) {
			return `
				background: ${props.theme.static.green9};
				color: ${props.theme.green1};
			`;
		} else if (props.type === LabelTypes.LABS) {
			return `
				background: ${props.theme.purple9};
				color: ${props.theme.isDarkMode ? props.theme.static.pureWhite : props.theme.static.purple1};
			`;
		} else if (props.type === LabelTypes.NUMBER) {
			return `
				background: ${props.theme.static.rose9};
				color: ${props.theme.static.rose1};
			`;
		}
	}}
`;

const Label = props => {
	const { className, type, notification = null } = props;
	let { text } = props;

	switch (type) {
		case LabelTypes.NEW:
			text = _`new`;
			break;
		case LabelTypes.LABS:
			text = _`labs`;
			break;
		case LabelTypes.NUMBER:
			text = notification;
			break;
		default:
			throw new InvalidPropError('Label.type should be one of LabelTypes');
	}

	return (
		<Container className={className} type={type}>
			{text}
			{!!notification && type !== LabelTypes.NUMBER && (
				<Notification>
					{notification}
				</Notification>
			)}
		</Container>
	);
};

export default Label;

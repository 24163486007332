import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useSelectApiStatus } from '@poki/rx-api';

import { getErrorDetailsForErrorId } from 'app/src/epics/data';
import { useSelectErrorDetailsPagination } from 'app/src/selectors/game';
import capitalizeFirstLetter from 'app/src/utils/capitalizeFirstLetter';
import { setErrorDetailsPagination } from 'app/src/actions/session';
import WarningMessage from 'app/src/components/ui/WarningMessage';
import { useSelectDataByEpic } from 'app/src/selectors/data';
import getVersionLabel from 'app/src/utils/getVersionLabel';
import formatNumber from 'app/src/utils/formatNumber';
import Table from 'app/src/components/ui/Table';
import _ from 'shared/copy';

const DetailsTitle = styled.div`
	font-weight: bold;
	line-height: 20px;
	padding: 16px;
`;

const StackTrace = styled.div`
	background: ${props => props.theme.static.grey1};
	border-radius: 8px;
	margin: 0 16px 16px 16px;
	padding: 16px;
	color: ${props => props.theme.static.pureWhite};
	font-family: Courier, Courier new, mono;
	overflow: auto;
	max-height: 412px;
	white-space: nowrap;
`;

const StackLine = styled.div`
`;

const StackURL = styled.a`
	color: ${props => props.theme.static.green5};

	&:hover {
		color: ${props => props.theme.static.green3};
		text-decoration: underline;
	}
`;

const StackFunc = styled.span`
	color: ${props => props.theme.static.rose5};
	font-weight: bold;
`;

const StackUnparsed = styled.span`
	color: ${props => props.theme.static.rose5};
	font-weight: bold;
	white-space: normal;
`;

const StackLineNr = styled.span`
	color: ${props => props.theme.static.yellow5};
`;

const StackColNr = styled.span`
	color: ${props => props.theme.static.purple5};
`;

const StyledMessageBox = styled(WarningMessage)`
	width: 80%;
	margin: 0 0 24px;
	padding: 0 10px;
	color: ${props => props.theme.yellow1};

	svg {
		margin-top: 3px;

		[fill] {
			fill: ${props => props.theme.yellow1};
		}
	}
`;

const errorDetailsPerPage = 10;

const ErrorDetails = props => {
	const { error, game, team, activeFilters, from, to } = props;

	const dispatch = useDispatch();

	const persistedPagination = useSelectErrorDetailsPagination(error?.error_id);
	const getErrorDetailsStatus = useSelectApiStatus(getErrorDetailsForErrorId.id);

	const [sort, setSort] = useState({ field: 'gameplays', direction: -1 });
	const [page, setPage] = useState(persistedPagination);

	const { rows: errors, total: totalErrors } = useSelectDataByEpic(getErrorDetailsForErrorId.id);

	useEffect(() => {
		if (!error || !team) return;

		dispatch(getErrorDetailsForErrorId.fetch({ gameId: game.id, errorId: error.error_id, teamId: team.id, from, to, sortField: sort.field, sortDirection: sort.direction, page, perPage: errorDetailsPerPage, filters: activeFilters }));
		dispatch(setErrorDetailsPagination({ id: error.error_id, page }));
	}, [error, team, sort, page, activeFilters]);

	let parsedStack = null;

	try {
		if (error.error_stack) {
			parsedStack = JSON.parse(error.error_stack);
		}
	} catch { }

	return (
		<>
			<DetailsTitle>{_`stackTrace`}</DetailsTitle>
			{error.error_message === 'Script error.' && (
				<StyledMessageBox title={_`scriptError`} message={_`scriptErrorExplanation`} />
			)}
			<StackTrace>
				<strong>{error.error_name ? `${error.error_name}${error.error_message ? ': ' : ''}` : ''}{error.error_message}</strong>
				{Array.isArray(parsedStack) ? parsedStack.map(stack => {
					const canLinkToFile = stack.url !== '<anonymous>';

					return (
						<StackLine>
							&nbsp;&nbsp;{/* monospace whitespace :) */}
							at <StackFunc>{stack.func}</StackFunc> [<StackURL href={canLinkToFile ? stack.url : '#'} target={canLinkToFile ? '_blank' : ''}>{stack.parsed_url || stack.url}</StackURL>:<StackLineNr>{stack.line}</StackLineNr>:<StackColNr>{stack.column}</StackColNr>]
						</StackLine>
					);
				}) : (
					<StackLine>
						&nbsp;&nbsp;{/* monospace whitespace :) */}
						<StackUnparsed>{error.error_stack}</StackUnparsed>
					</StackLine>
				)}
			</StackTrace>
			<DetailsTitle>{_`deviceAndBrowserBreakdown`}</DetailsTitle>
			<Table
				items={errors}
				isLoading={!getErrorDetailsStatus.done}
				compact
				setSort={setSort}
				sortField={sort.field}
				sortDirection={sort.direction}
				setPagination={setPage}
				page={persistedPagination}
				perPage={errorDetailsPerPage}
				totalItems={totalErrors}
				columns={[
					{
						title: _`gameplays`,
						width: '150px',
						content: ({ item: _item }) => <>{formatNumber(_item.gameplays)}</>,
						sortField: 'gameplays',
					},
					{
						title: _`totalErrors`,
						width: '150px',
						content: ({ item: _item }) => <>{formatNumber(_item.errors)}</>,
						sortField: 'errors',
					},
					{
						title: _`device`,
						width: 'max-content',
						content: ({ item: _item }) => <>{capitalizeFirstLetter(_item.device_category)}</>,
					},
					{
						title: _`browser`,
						width: 'max-content',
						content: ({ item: _item }) => <>{_item.browser_name} {_item.browser_version}</>,
					},
					{
						title: _`version`,
						content: ({ item: _item }) => {
							const version = game.versions.find(v => v.id === _item.p4d_game_version_id);

							return (
								version ? (
									getVersionLabel(version)
								) : (
									'Unknown External'
								)
							);
						},
					},
				]}
			/>
		</>
	);
};

export default ErrorDetails;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';

import ChevronDownIcon from 'shared/designTokens/icons/ui/small/ChevronDownIcon';
import CloseIcon from 'shared/designTokens/icons/ui/small/CloseIcon';

import { motionOut, motionSpeed02 } from 'shared/vars';

const MultiSelectInputContainer = styled.fieldset`
	position: relative;
	min-width: 0;
	max-width: 500px;
	margin-bottom: 16px;
`;

const MultiSelectInputSelectedWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;

	height: fit-content;
	width: 100%;
	padding: 9px 17px;

	line-height: 48px;
	font-size: 16px;

	background: ${props => props.theme.grey7};
	color: ${props => props.theme.denimBlue};

	border: 1px solid ${props => props.theme.grey5};
	border-radius: 8px;

	transition: background ${motionSpeed02} ${motionOut}, color ${motionSpeed02} ${motionOut};

	${props => props.isFocused && `
		outline: none;
		border-color: ${props.theme.pokiBlue};
		box-shadow: inset 0 0 0 2px ${props.theme.pokiBlue};
		background: ${props.theme.grey9};
	`}

	${props => (props.disabled && `
		cursor: not-allowed;
		border: none;
	`)}
`;

const MultiSelectInputSelectedOptions = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-right: 6px;
	gap: 8px;
	font-size: 14px;
`;

const MultiSelectInputDropdownContainer = styled.div`
	--translateY: ${props => props.selectedRef.current.clientHeight + 24}px;

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
	list-style: none;

	max-height: 200px;
	overflow-y: auto;

	border-radius: 8px;
	margin-top: 8px;

	background: ${props => props.theme.pureWhite};
	box-shadow: ${props => props.theme.boxShadowSmall};

	transform: translateY(var(--translateY));
`;

const MultiSelectInputDropdownItem = styled.div`
	padding: 8px 16px;

	&:hover {
		cursor: pointer;
		background-color: ${props => props.theme.grey7};
	}
`;

const MultiSelectInputIndicators = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
`;

const MultiSelectInputDropdown = styled.span`
	display: flex;
	align-items: center;
	height: 100%;
	padding: 0 8px;

	svg {
		[fill] {
			fill: ${props => (props.isFocused ? props.theme.pokiBlue : props.theme.grey3)}};
		}
	}
`;

const MultiSelectInputSearch = styled.input`
	width: 100%;
	max-width: 120px;
	padding: 8px 0;
	border: none;
	background: transparent;
	outline: none;

	${props => props.disabled && `
		cursor: not-allowed;
	`}
`;

const MultiSelectInputPill = styled.div`
	display: flex;
	align-items: center;

	background: ${props => props.theme.pureWhite};
	color: ${props => props.theme.grey1};
	border-radius: 8px;
	padding: 4px 8px;
	height: 30px;
	display: flex;
	align-items: center;
	gap: 4px;
`;

const MultiSelectInputPillRemove = styled.span`
	display: flex;
	align-items: center;
	height: 100%;
	font-size: 12px;
	cursor: pointer;
	color: ${props => props.theme.grey3};
	font-weight: bold;

	svg {
		width: 18px;
		height: 18px;

		[fill] {
			fill: ${props => props.theme.grey3};
		}
	}

	&:hover {
		svg {
			[fill] {
				fill: ${props => props.theme.rose1};
			}
		}
	}

	${props => props.disabled && `
		pointer-events: none;
	`}
`;

const MultiSelectInputLabel = styled.div`
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: ${props => props.theme.grey3};
	margin-bottom: 8px;
`;

const MultiSelectInputPlaceholder = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 16px;
	line-height: 30px;
`;

const MultiSelectInputDescription = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	min-height: 18px;
`;

const MultiSelectInputError = styled.div`
	color: ${props => props.theme.rose1};
	font-size: 14px;
	line-height: 18px;

	& + & {
		margin-top: 0;
	}
`;

const MultiSelectInput = props => {
	const { value, name, onChange, valueSetter, label, values, placeholder, disabled, errors = [], description, maxSelection, enableSearch = false } = props;

	const containerRef = useRef();
	const selectedRef = useRef();

	const [isFocused, setIsFocused] = useState(false);
	const [currentOptions, setCurrentOptions] = useState(values.sort((a, b) => a.desc.localeCompare(b.desc)));
	const [selectedOptions, setSelectedOptions] = useState(value || []);
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		if (!isEqual(value, selectedOptions)) {
			setSelectedOptions(value);
		}
	}, [value]);

	const toggleDropdown = () => {
		if (disabled) {
			return;
		}

		setIsDropdownVisible(!isDropdownVisible);
		setIsFocused(true);
	};

	const handleClickOutside = event => {
		if (containerRef.current && !containerRef.current.contains(event.target)) {
			setIsDropdownVisible(false);
			setIsFocused(false);
		}
	};

	const handleOptionSelect = option => {
		if (maxSelection && selectedOptions.length >= maxSelection) {
			return;
		}

		const { value: optionValue, desc: optionDesc } = option;

		setIsDropdownVisible(false);
		setSelectedOptions([...selectedOptions, optionDesc]);
		setCurrentOptions(currentOptions.filter(currentOption => currentOption.value !== optionValue));
		setSearchTerm('');
	};

	const handleOptionRemove = (e, option) => {
		e.stopPropagation();

		setSelectedOptions(selectedOptions.filter(selectedOption => selectedOption !== option));
		setCurrentOptions([...currentOptions, values.find(currentOption => currentOption.value === option)]);
	};

	// Filter options based on search input
	const filteredOptions = useMemo(() => (
		currentOptions.filter(option => option?.desc.toLowerCase().includes(searchTerm.toLowerCase()))
	), [currentOptions, searchTerm]);

	useEffect(() => {
		// filter out selected options from current options
		setCurrentOptions(values.filter(option => !selectedOptions.includes(option.desc)));
	}, [selectedOptions]);

	useEffect(() => {
		if (onChange) {
			onChange(selectedOptions);
		}

		if (valueSetter) {
			valueSetter(selectedOptions);
		}
	}, [selectedOptions]);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<MultiSelectInputContainer ref={containerRef}>
			{label && <MultiSelectInputLabel>{label}</MultiSelectInputLabel>}
			<MultiSelectInputSelectedWrapper disabled={disabled} isFocused={isFocused} onClick={toggleDropdown} ref={selectedRef}>
				<MultiSelectInputSelectedOptions>
					{selectedOptions.length === 0 && !enableSearch && <MultiSelectInputPlaceholder>{placeholder}</MultiSelectInputPlaceholder>}
					{selectedOptions.map(selectedOption => (
						<MultiSelectInputPill key={selectedOption}>
							{selectedOption}
							<MultiSelectInputPillRemove onClick={e => handleOptionRemove(e, selectedOption)} disabled={disabled}>
								<CloseIcon />
							</MultiSelectInputPillRemove>
						</MultiSelectInputPill>
					))}
					{enableSearch && (
						<MultiSelectInputSearch
							type="text"
							placeholder="Search..."
							disabled={disabled}
							value={searchTerm}
							onChange={e => setSearchTerm(e.target.value)}
						/>
					)}
				</MultiSelectInputSelectedOptions>
				<MultiSelectInputIndicators>
					<MultiSelectInputDropdown isFocused={isFocused}>
						<ChevronDownIcon />
					</MultiSelectInputDropdown>
				</MultiSelectInputIndicators>
			</MultiSelectInputSelectedWrapper>
			{isDropdownVisible && (
				<MultiSelectInputDropdownContainer selectedRef={selectedRef}>
					<div>
						{filteredOptions.map(option => (
							<MultiSelectInputDropdownItem key={option.desc} onClick={() => handleOptionSelect(option)}>{option.desc}</MultiSelectInputDropdownItem>
						))}
					</div>
				</MultiSelectInputDropdownContainer>
			)}
			{errors.length > 0 ? errors.map(err => <MultiSelectInputError key={err}>{err}</MultiSelectInputError>) : (
				description && (
					<MultiSelectInputDescription>{description}</MultiSelectInputDescription>
				)
			)}
			<input type="hidden" name={name} value={value} />
		</MultiSelectInputContainer>
	);
};

export default MultiSelectInput;

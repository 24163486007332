const playerFitTestsFAQItems = [
	{
		isInitiallyOpen: true,
		question: 'What is Player Fit Test?',
		answer: 'The Player Fit Test is an early checkpoint designed to evaluate whether your game is ready to be introduced to thousands of players.',
	},
	{
		question: 'How many players will see my game?',
		answer: 'At this stage, your game will be shown to 500 players. This number was determined after a extensive analysis of player data, as we found that insights gathered from 500 players are generally representative for early-stage testing.',
	},
	{
		question: 'What does my game need to achieve to proceed to the next stage?',
		answer: 'To pass the Player Fit Test and advance to the WebFit Test, your game must achieve an average playtime of at least 3 minutes.',
	},
	{
		question: 'Do I need a game thumbnail for a Player Fit Test?',
		answer: 'No, a thumbnail is not required for a Player Fit Test. Once you request a test, a mystery game thumbnail will automatically appear on the Poki playground for a selected group of players.',
	},
	{
		question: 'How long does it take to get the results of a Player Fit Test?',
		answer: 'The results of a Player Fit Test may take up to 3 hours, depending on the current testing conditions.',
	},
	{
		question: 'What does the Player Fit graph show?',
		answer: 'The Player Fit graph shows the distribution of players based on their playtime ranges. For example, it illustrates how many of the 500 players stopped playing within 30 seconds or played for more than 3 minutes. The graph compares data from your last two game versions, helping you track improvements or changes.',
	},
	{
		question: 'How many Player Fit tests can I request per day?',
		answer: 'Due to the time required to find players for game testing, you can request up to 2 Player Fit tests per day. This limit ensures a fair opportunity for all developers.',
	},
	{
		question: 'Can I test a version that already failed a Player Fit Test?',
		answer: 'Yes, you can. However, we strongly recommend using your credits to test an updated or improved version of your game. This stage is a perfect opportunity to refine your game and ensure it’s ready. Keep in mind that once your game reaches the Web Fit stage, the result will be permanent. Take advantage of this phase to iterate and perfect your game before advancing.',
	},
];

export default playerFitTestsFAQItems;

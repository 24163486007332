import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PT from 'prop-types';

import DiamondIcon from 'shared/designTokens/icons/ui/medium/DiamondIcon';
import UploadIcon from 'shared/designTokens/icons/ui/medium/UploadIcon';
import GameIcon from 'shared/designTokens/icons/ui/medium/GameIcon';
import RocketIcon from 'shared/designTokens/icons/ui/medium/RocketIcon';
import LineGraphIcon from 'shared/designTokens/icons/ui/medium/LineGraphIcon';
import CheckIcon from 'shared/designTokens/icons/ui/medium/CheckIcon';
import LockIcon from 'shared/designTokens/icons/ui/medium/LockIcon';

import { useSelectGameChecklistLevelStatus } from 'app/src/selectors/session';

import ChecklistStep from 'app/src/components/ui/ChecklistStep';

import { smallMedia, isMobile } from 'shared/vars';

const CardContainer = styled.div`
	width: 100%;
	position: relative;
	background: ${props => props.theme.pureWhite};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	box-shadow: ${props => props.theme.boxShadowSmall};
	min-width: 0;

	& & {
		box-shadow: none;
		border: 1px solid ${props => props.theme.grey7};
	}


	p {
		font-size: 16px;
		line-height: 24px;
		color: ${props => props.theme.grey3};
	}

	p + h3,
	p + & {
		margin-top: 54px;
	}

	h1 + p,
	h2 + p,
	h3 + p,
	h4 + p,
	h5 + p {
		margin-top: -16px;
	}
`;

const Level = styled.h4`
	font-size: 12px;
	line-height: 16px;
	margin: 0;
	color: ${props => props.theme.grey3};
`;

const Title = styled.h3`
	font-size: 21px;
	margin: 0;
	display: flex;
`;

const LevelIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 36px;
	padding: 2px;
	border-radius: 36px;
	background: ${props => (props.completed ? props.theme.green4 : props.theme.grey7)};

	${smallMedia}{
		width: 40px;
		height: 40px;
	}
`;

const StyledLevelIcon = Icon => styled(Icon)`
  width: 24px;
  height: 24px;

  & > * {
    fill: ${({ $completed, $current, theme }) => (
		$completed ? theme.pureWhite : $current ? theme.pokiBlue : theme.grey5
	)};
  }
`;

const StyledCompletedIcon = StyledLevelIcon(CheckIcon);
const StyledDiamondIcon = StyledLevelIcon(DiamondIcon);
const StyledUploadIcon = StyledLevelIcon(UploadIcon);
const StyledGameIcon = StyledLevelIcon(GameIcon);
const StyledRocketIcon = StyledLevelIcon(RocketIcon);
const StyledLineGraphIcon = StyledLevelIcon(LineGraphIcon);
const StyledLockIcon = StyledLevelIcon(LockIcon);

const Head = styled.div`
	display: flex;
	align-items: center;
	gap: 39px;
	flex: 1 0 0;
	padding: 16px;
	cursor: cursor;

	${({ $active }) => $active && `
		padding-bottom: 0;
	`}

	${({ $completed, $active }) => !$completed && !$active && `
		cursor: pointer;
	`}

	${isMobile && `
		gap: 12px;
		padding: 16px;
	`}

	${({ $completed, $current, theme }) => !$completed && !$current && `

		${Title}, ${Level} {
			color: ${theme.grey5};
		}
	`}
`;

const Left = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
`;

const OuterFrame = styled.div`
	display: flex;
	padding: 24px;
	align-items: flex-start;
	gap: 40px;
	align-self: stretch;

	${isMobile && `
		padding: 0 16px 16px 16px;
	`}

	${props => !props.active && `
		height: 0;
		overflow: hidden;
		padding-top: 0;
	`}
`;

const Frame = styled.div`
	display: flex;
	padding: 0px 0px 0 94px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	flex: 1 0 0;

	${isMobile && `
		padding: 0;
		overflow: hidden;
	`}
`;

const LockedFrame = styled.div`
	padding: 0 72px;
`;

const Description = styled.div`
	font-size: 16px;
	font-family: Proxima Nova;
	color: ${props => props.theme.grey3};
	align-self: stretch;
`;

const Steps = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	position: relative;

	${smallMedia} {
		width: 664px;
	}
`;

const LOCKED_DESCRIPTIONS = {
	playtest: 'Upload your game to start getting feedback from players.',
	playerfit: 'Watch at least 5 Playtest videos to proceed to the Player Fit Test and gain more insight.',
	webfit: 'Your game must reach 3 minutes of playtime during the Player Fit Test to proceed to the Web Fit Test.',
};

const ChecklistCard = props => {
	const { id, className, onClick, title, description, subComponent, level, steps, checkType, game, active, current, completed, icon = 'upload' } = props;

	const stepsContainerRef = useRef(null);

	const [checkedItems, setCheckedItems] = useState([]);
	const [step, setStep] = useState(0);

	const levelStatus = useSelectGameChecklistLevelStatus(game?.id, id);

	const isLocked = !completed && !current;

	useEffect(() => {
		if (levelStatus) {
			// The Webfit part has it's own logic because there are many stages that are all
			// part of the Webfit tests that all should result into different states.
			if (id === 'webfit') {
				const stageToStep = {
					versions_uploaded: levelStatus[0]?.status === 'completed' ? 1 : 0,
					running_playtests: levelStatus[0]?.status === 'completed' ? 1 : 0,
					running_player_fit_tests: levelStatus[0]?.status === 'completed' ? 1 : 0,
					poki_web_fit_requested: 2, // Show the "waiting for result" step.
					advised_to_continue_playtests: 1, // Show the step to start a Webfit test.
					poki_web_fit_eligible_but_needs_tweaks: 1,
					running_poki_web_fit_test: 2,
					poki_web_fit_score_to_send: 2,
					advised_to_continue_iterating: 1,
					share_positive_poki_web_fit_score: 3,
				};

				const currentStep = stageToStep[game?.self_service_stage] || 0;
				const newCheckedItems = steps.map((_, i) => i < currentStep);
				setCheckedItems(newCheckedItems);
				setStep(currentStep > 2 ? 2 : currentStep);
			} else {
				let lastCompletedStep = -1;
				const newCheckedItems = steps.map(() => false);

				for (let i = 0; i < levelStatus.length; i++) {
					if (levelStatus[i]?.status === 'completed') {
						newCheckedItems[i] = true;
						lastCompletedStep = i;
					}
				}

				lastCompletedStep++;
				if (lastCompletedStep > steps.length - 1) {
					lastCompletedStep = steps.length - 1;
				}

				setCheckedItems(newCheckedItems);

				if (lastCompletedStep >= 0) {
					setStep(lastCompletedStep);
				}
			}
		} else if (current) {
			setStep(0);
		}
	}, [levelStatus, steps, current, game?.self_service_stage]);

	const handleSelectStep = index => {
		setStep(index);
	};

	const handleCheck = () => {
		const nextStep = Math.min(step + 1, steps.length - 1);
		handleSelectStep(nextStep);
	};

	const GetLevelIcon = ({ completed: iconCompleted, current: iconCurrent }) => {
		if (completed) {
			return <StyledCompletedIcon $completed={iconCompleted} $current={iconCurrent} />;
		}

		if (isLocked) {
			return <StyledLockIcon $completed={iconCompleted} $current={iconCurrent} />;
		}

		switch (icon) {
			case 'upload':
				return <StyledUploadIcon $completed={iconCompleted} $current={iconCurrent} />;
			case 'game':
				return <StyledGameIcon $completed={iconCompleted} $current={iconCurrent} />;
			case 'rocket':
				return <StyledRocketIcon $completed={iconCompleted} $current={iconCurrent} />;
			case 'graph':
				return <StyledLineGraphIcon $completed={iconCompleted} $current={iconCurrent} />;
			default:
				return <StyledDiamondIcon $completed={iconCompleted} $current={iconCurrent} />;
		}
	};

	const activateClick = active || completed ? () => {} : onClick;

	return (
		<CardContainer className={className} onClick={activateClick}>
			{(title || description || subComponent) && (
				<Head $completed={completed} $current={current} $active={active} $canClick={!completed}>
					<LevelIcon completed={completed}>
						<GetLevelIcon completed={completed} current={current} />
					</LevelIcon>
					<Left>
						<Level>
							{`LEVEL ${level}`}
						</Level>
						<Title>
							{title}
						</Title>
					</Left>
				</Head>
			)}
			{active && (
				<OuterFrame active={active}>
					{isLocked && LOCKED_DESCRIPTIONS[id] ? (
						<LockedFrame>
							<Description>{LOCKED_DESCRIPTIONS[id]}</Description>
						</LockedFrame>
					) : (
						<Frame>
							{description && <Description>{description}</Description>}
							{subComponent || (
								steps.length > 0 && (
									<Steps ref={stepsContainerRef}>
										{steps.map((data, i) => (
											<ChecklistStep
												key={`step-${data.title}`}
												title={data.title}
												contentGenerator={data.contentGenerator}
												onSubmitCallback={handleCheck}
												onClick={() => handleSelectStep(i)}
												checkType={checkType}
												active={i === step}
												disabled={!current || checkedItems[i] || (i === 0 ? !checkType[0] : !checkedItems[i - 1])}
												checked={checkedItems[i]}
												current={i === 0 || checkedItems[i - 1]}
												game={game}
											/>
										))}
									</Steps>
								)
							)}
						</Frame>
					)}
				</OuterFrame>
			)}
		</CardContainer>
	);
};

ChecklistCard.propTypes = {
	className: PT.string,
	title: PT.string,
	level: PT.string,
	description: PT.string,
	steps: PT.arrayOf(PT.shape({
		title: PT.string,
		children: PT.string,
	})),
};

ChecklistCard.defaultProps = {
	className: null,
	title: null,
	level: null,
	description: null,
	steps: [],
};

export default ChecklistCard;

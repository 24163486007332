import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';
import { map, merge } from 'rxjs';
import moment from 'moment';
import styled from 'styled-components';

import { createNewPrompt, getPromptsList, downloadPromptsCSV, testPrompt, downloadSampleMessages } from 'app/src/epics/guardian';
import { useSelectPromptsList, useSelectTestResult } from 'app/src/selectors/guardian';

import { guardianLanguages } from 'app/src/utils/guardian';
import { dayMonthYearTimeFormat } from 'shared/vars';

import DownloadIcon from 'shared/designTokens/icons/ui/small/DownloadIcon';

import TextAreaInput from 'app/src/components/input/TextAreaInput';
import FileInput from 'app/src/components/input/FileInput';
import TextInput from 'app/src/components/input/TextInput';
import Button from 'app/src/components/ui/Button';
import Card from 'app/src/components/ui/Card';
import Table from 'app/src/components/ui/Table';
import Tooltip from 'shared/components/Tooltip';
import GridContainer from 'app/src/components/ui/GridContainer';
import IconButton from 'app/src/components/ui/IconButton';
import CopyToClipboard from 'app/src/components/CopyToClipboard';
import SelectInput from 'app/src/components/input/SelectInput';

const StyledTooltip = styled(Tooltip)`
	text-decoration: underline dotted;
`;

const StyledTextAreaInput = styled(TextAreaInput)`
max-width: 100%;
`;

function formatTestResult(result) {
	const keys = Object.keys(result).sort();
	const out = [];

	for (const key of keys) {
		out.push(`${key}: ${result[key]}`);
	}

	return out.join('\n');
}

const AdminGuardianPage = () => {
	const dispatch = useDispatch();

	const [prompt, setPrompt] = useState(`
Analyse the following chat message to determine if it contains harmful content that should be blocked.

Mark the message as 'harmful' **only if**:
- It directly targets individuals or groups with hate speech, violent threats, or explicit harassment
- It contains clearly discriminatory remarks, scams, impersonation, or attempts to provoke harm
- It includes explicit sexual content or graphic violence inappropriate for general audiences
- It includes or request personal names, the names of schools, specific geographical locations, or any identifiable personal information
- It contains any reference to dating or relationships
- It attempts to convince others to go to private rooms, external websites, or platforms (like Instagram, Snapchat, Sylaps, TikTok, Whatsapp, VK and similar), or involves sharing codes, links, or invitations to access private rooms or groups
- It contains controversial signs like 卍 :peach: :eggplant::gun:
- Messages expressing dissatisfaction or discontent about moderation should not be marked as harmful unless they contain other harmful elements as outlined above

Meme-like content and humor is allowed unless they cross a line into harmful territory. Use context and leniency when deciding.

Additionally, specify the ISO 639 language code for the detected language of the message, not the context (or XX if really not sure).

If you can't detect the language mark the message as harmful as well!

These are the usernames of the people in the lobby, you should be able to mention them by name: {{usernames}}

{{if_context}}
Here are the latest message including the history of the chat, also analyse it in this context:
\`\`\`
{{context}}
\`\`\`
{{end_if_context}}

This is the latest message by {{username}}:
\`\`\`
{{text}}
\`\`\`
		`.trim());
	const [message, setMessage] = useState('how are you?');
	const [user, setUser] = useState('user1');
	const [context, setContext] = useState('user1: hi\nuser2: hello');
	const [users, setUsers] = useState('user1, user2');
	const [type, setType] = useState('CHAT');
	const [sampleType, setSampleType] = useState('CHAT');
	const [sampleLimit, setSampleLimit] = useState(100);
	const [language, setLangugage] = useState('');
	const [downloadGameId, setDownloadGameId] = useState('');
	const [csvFile, setCsvFile] = useState();
	const [page, setPage] = useState(1);
	const [model, setModel] = useState('gemini-1.5-flash-002');

	const createNewPromptStatus = useSelectApiStatus(createNewPrompt.id);
	const downloadPromptsCSVStatus = useSelectApiStatus(downloadPromptsCSV.id);
	const getPromptsListStatus = useSelectApiStatus(getPromptsList.id);
	const testPromptStatus = useSelectApiStatus(testPrompt.id);

	const prompts = useSelectPromptsList();
	const testResult = useSelectTestResult();

	const tableLoadingDone = (prompts && prompts.length > 0) || getPromptsListStatus.done;

	useEffect(() => {
		const int = setInterval(() => {
			dispatch(getPromptsList.fetch());
		}, 1000 * 5);

		return () => clearInterval(int);
	}, []);

	const handleCreatePrompt = () => {
		const formData = new FormData();
		formData.set('prompt', prompt.trim());
		formData.set('type', type);
		formData.set('csv', csvFile);
		formData.set('model', model);

		dispatch(createNewPrompt.fetch({ formData }, ({ success$ }) => merge(
			success$.pipe(
				map(() => getPromptsList.fetch()),
			),
		)));
	};

	const handleDownload = id => {
		dispatch(downloadPromptsCSV.fetch({ id }));
	};

	const handleTestPrompt = () => {
		dispatch(testPrompt.fetch({
			prompt: prompt.trim(),
			message,
			user,
			context: context.trim().split('\n'),
			users: users.trim().split(',').map(u => u.trim()),
			model,
		}));
	};

	const handleDownloadMessages = () => {
		dispatch(downloadSampleMessages.fetch({ type: sampleType, limit: parseInt(sampleLimit, 10), language, gameId: downloadGameId }));
	};

	return (
		<GridContainer cols={1}>
			<Card
				title="Download latest messages"
			>
				<SelectInput
					label="Type"
					disabled={downloadSampleMessages.pending}
					value={sampleType}
					valueSetter={setSampleType}
					values={[
						{ value: 'CHAT', key: 'CHAT', desc: 'CHAT' },
						{ value: 'USERNAME', key: 'USERNAME', desc: 'USERNAME' },
						{ value: 'MEME', key: 'MEME', desc: 'MEME' },
					]}
				/>
				<SelectInput
					label="Language"
					disabled={downloadSampleMessages.pending}
					value={language}
					valueSetter={setLangugage}
					values={guardianLanguages.map(({ value, desc }) => ({ value, key: value, desc }))}
				/>
				<SelectInput
					label="Game"
					disabled={downloadSampleMessages.pending}
					value={downloadGameId}
					valueSetter={setDownloadGameId}
					values={[
						{ value: '', key: 'empty', desc: '-' },
						{ value: '977b60eb-ff81-4c3a-895b-b585410d6c0d', key: 'make-it-meme', desc: 'Make It Meme' },
						{ value: 'e2c6282e-13db-47a4-99c8-3297118978c1', key: 'narrow-one', desc: 'Narrow.One' },
					]}
				/>
				<TextInput
					label="Amount"
					disabled={downloadSampleMessages.pending}
					value={sampleLimit}
					valueSetter={setSampleLimit}
				/>
				<Button onClick={handleDownloadMessages}>Download sample</Button>
			</Card>
			<Card
				title="Prompt"
			>
				<StyledTextAreaInput
					label="Prompt"
					name="prompt"
					disabled={createNewPromptStatus.pending || testPromptStatus.pending}
					value={prompt}
					valueSetter={setPrompt}
					height="800px"
				/>
			</Card>
			<Card
				title="Test single message"
			>
				<StyledTextAreaInput
					label="Message"
					name="message"
					disabled={testPromptStatus.pending}
					value={message}
					valueSetter={setMessage}
					height="100px"
				/>
				<TextInput
					label="User"
					name="user"
					disabled={testPromptStatus.pending}
					value={user}
					valueSetter={setUser}
				/>
				<StyledTextAreaInput
					label="Context"
					name="context"
					disabled={testPromptStatus.pending}
					value={context}
					valueSetter={setContext}
				/>
				<TextInput
					label="Users"
					name="users"
					disabled={testPromptStatus.pending}
					value={users}
					valueSetter={setUsers}
				/>
				<SelectInput
					label="Model"
					disabled={testPromptStatus.pending}
					value={model}
					valueSetter={setModel}
					values={[
						{ value: 'gemini-1.5-flash-002', key: 'gemini-1.5-flash-002', desc: 'gemini-1.5-flash-002' },
						{ value: 'gemini-2.0-flash-001', key: 'gemini-2.0-flash-001', desc: 'gemini-2.0-flash-001' },
						{ value: 'gemini-2.0-flash-lite-preview-02-05', key: 'gemini-2.0-flash-lite-preview-02-05', desc: 'gemini-2.0-flash-lite-preview-02-05' },
					]}
				/>
				<Button onClick={handleTestPrompt}>Test Prompt</Button>
				{testResult && !testPromptStatus.pending && (
					<Card
						title="Test Result"
					>
						<pre>{formatTestResult(testResult)}</pre>
					</Card>
				)}
			</Card>
			<Card
				title="Test multiple messages"
			>
				<SelectInput
					label="Type"
					disabled={createNewPromptStatus.pending}
					value={type}
					valueSetter={setType}
					values={[
						{ value: 'CHAT', key: 'CHAT', desc: 'CHAT' },
						{ value: 'USERNAME', key: 'USERNAME', desc: 'USERNAME' },
						{ value: 'MEME', key: 'MEME', desc: 'MEME' },
					]}
				/>
				<SelectInput
					label="Model"
					disabled={createNewPromptStatus.pending}
					value={model}
					valueSetter={setModel}
					values={[
						{ value: 'gemini-1.5-flash-002', key: 'gemini-1.5-flash-002', desc: 'gemini-1.5-flash-002' },
						{ value: 'gemini-2.0-flash-001', key: 'gemini-2.0-flash-001', desc: 'gemini-2.0-flash-001' },
						{ value: 'gemini-2.0-flash-lite-preview-02-05', key: 'gemini-2.0-flash-lite-preview-02-05', desc: 'gemini-2.0-flash-lite-preview-02-05' },
					]}
				/>
				<FileInput
					label="CSV"
					accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					webkitdirectory=""
					directory=""
					required
					value={csvFile}
					valueSetter={setCsvFile}
				/>
				<Button onClick={handleCreatePrompt}>Test Prompt</Button>
				<Card
					title="Results"
					noPadding
				>
					<Table
						isLoading={!tableLoadingDone}
						items={prompts || []}
						setPagination={setPage}
						page={page}
						perPage={10}
						totalItems={(prompts || []).length}
						autoPaging
						columns={[
							{
								title: 'ID',
								content: ({ item }) => item.id,
							},
							{
								title: 'created',
								content: ({ item }) => moment(item.created_at).format(dayMonthYearTimeFormat),
							},
							{
								title: 'prompt',
								content: ({ item }) => (
									item.prompt.length > 10 ? (
										<CopyToClipboard text={item.prompt} />
									) : null
								),
							},
							{
								title: 'Type',
								content: ({ item }) => item.type,
							},
							{
								title: 'Samples',
								content: ({ item }) => item.sample_size,
							},
							{
								title: 'old reject rate',
								content: ({ item }) => {
									const total = item.old_reject_count + item.old_accept_count;
									const percentage = (total === 0) ? 0 : Math.round((item.old_reject_count / total) * 100);

									return (
										<StyledTooltip
											arrow
											placement="top-end"
											maxWidth="250px"
											content={(
												<>
													{item.old_reject_count} / {total}
												</>
											)}
										>
											{percentage}%
										</StyledTooltip>
									);
								},
							},
							{
								title: 'new reject rate',
								content: ({ item }) => {
									const total = item.new_reject_count + item.new_accept_count;
									const percentage = (total === 0) ? 0 : Math.round((item.new_reject_count / total) * 100);

									return (
										<StyledTooltip
											arrow
											placement="top-end"
											maxWidth="250px"
											content={(
												<>
													{item.new_reject_count} / {total}
												</>
											)}
										>
											{percentage}%
										</StyledTooltip>
									);
								},
							},
							{
								title: 'finished',
								content: ({ item }) => (item.finished ? 'Yes' : `No (${Math.round(((item.old_accept_count + item.old_reject_count) / item.sample_size) * 100)}%)`),
							},
							{
								title: 'download results',
								content: ({ item }) => (
									<IconButton
										icon={DownloadIcon}
										onClick={() => handleDownload(item.id)}
										disabled={downloadPromptsCSVStatus.pending}
										title={downloadPromptsCSVStatus.pending ? 'downloading' : 'download results'}
									/>
								),
							},
						]}
					/>
				</Card>
			</Card>
		</GridContainer>
	);
};

export default AdminGuardianPage;

const playtestAudience = [
	{
		value: '1',
		desc: 'Racing Games',
	},
	{
		value: '2',
		desc: 'Sports Games',
	},
	{
		value: '3',
		desc: 'Action Games',
	},
	{
		value: '6',
		desc: 'Adventure Games',
	},
	{
		value: '7',
		desc: 'Brain Games',
	},
	{
		value: '8',
		desc: 'Board Games',
	},
	{
		value: '9',
		desc: 'Skill Games',
	},
	{
		value: '16',
		desc: 'Hidden Object Games',
	},
	{
		value: '18',
		desc: 'Cooking Games',
	},
	{
		value: '29',
		desc: 'Dress Up Games',
	},
	{
		value: '48',
		desc: 'Platform Games',
	},
	{
		value: '51',
		desc: 'Motorbike Games',
	},
	{
		value: '77',
		desc: 'Shooting Games',
	},
	{
		value: '78',
		desc: 'Car Games',
	},
	{
		value: '84',
		desc: 'Card Games',
	},
	{
		value: '750',
		desc: '2 Player Games',
	},
	{
		value: '1014',
		desc: 'Idle Games',
	},
	{
		value: '1120',
		desc: '.io Games',
	},
	{
		value: '1130',
		desc: 'Clicker Games',
	},
	{
		value: '1155',
		desc: 'Merge Games',
	},
	{
		value: '1162',
		desc: 'Horror Games',
	},
];

export default playtestAudience;

export function getPlaytestAudience(playtest) {
	if (!playtest?.categories) {
		return 'All';
	}

	const categories = playtest.categories.split(',');
	const audiences = [];

	categories.forEach(category => {
		const audience = playtestAudience.find(a => a.value === category);

		if (audience) {
			audiences.push(audience.desc);
		}
	});

	return audiences.join(', ');
}

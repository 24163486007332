import React, { useRef } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import GameIcon from 'shared/designTokens/icons/ui/medium/GameIcon';

import { pushEvent } from 'app/src/utils/tracking';
import { openModal } from 'app/src/actions/client';

import Button from 'app/src/components/ui/Button';

import { smallMedia } from 'shared/vars';
import _ from 'shared/copy';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 16px;
	
	width: 100%;
	max-width: 444px;
	padding: 32px;

	background: ${props => props.theme.grey7};
	border-radius: 4px;
`;

const UpperContainer = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	align-self: stretch;
	gap: 16px;

	${smallMedia} {
		flex-direction: row;
	}
`;

const LeftContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	flex: 1 0 0;

	${smallMedia} {
		order: 1;
	}
`;

const StyledHeader = styled.h4`
	font: 700 16px/20px "Proxima Nova";
	margin: 0;
`;

const Buttons = styled.div`
	display: flex;
	gap: 16px;
`;

const StyledGameIcon = styled(GameIcon)`
	width: 64px;
	height: 64px;

	${smallMedia} {
		width: 108px;
		height: 108px;
		order: 2;
	}
`;

const StyledLink = styled.a`
	color: ${props => props.theme.blue};
	text-decoration: none;
	font: 700 16px/24px "Proxima Nova";
`;

const FooterText = styled.div`
	color: ${props => props.theme.grey3};
	font: 400 12px/16px "Proxima Nova";
`;

const Level3Step2 = ({ disabled, game }) => {
	const dispatch = useDispatch();

	const containerRef = useRef(null);

	// Games in these stages are currently running a Web Fit Test or don't have to run them anymore.
	const pending = [
		'poki_web_fit_requested',
		'running_poki_web_fit_test',
		'poki_web_fit_score_to_send',
		'share_positive_poki_web_fit_score',
	].includes(game?.self_service_stage);

	const handleOpenRequestWebFitTest = () => {
		dispatch(openModal({ key: 'request-web-fit-test', data: { gameId: game.id } }));
	};

	const onClickTermsAndConditions = () => {
		pushEvent('gameCreateFlow', 'termsAndConditions', {
			gameId: game.id,
		});
	};

	return (
		<Container ref={containerRef}>
			<UpperContainer>
				<StyledGameIcon />
				<LeftContainer>
					<StyledHeader>
						{pending ? _`preppingGame` : _`liveForAWeek`}
					</StyledHeader>
					{pending ? _`notifiedByEmail` : (
						<>
							<div>
								{_`gameWillBeLive`}
							</div>
							<div>
								Before requesting a test, check if your game complies with our <StyledLink onClick={onClickTermsAndConditions} href="/2024.03.13_Terms_and_Conditions_Poki_for_Developers.pdf" download="2024-03-13 Terms and Conditions Poki for Developers.pdf" target="_blank">Terms & Conditions</StyledLink>. Because we care about the safety of our players.
							</div>
						</>
					)}
				</LeftContainer>
			</UpperContainer>

			{pending ? <FooterText>{_`takesAboutDays`}</FooterText>
				: (
					<Buttons>
						<Button onClick={handleOpenRequestWebFitTest} disabled={disabled}>{_`requestWebFitTest`}</Button>
					</Buttons>
				)}
		</Container>
	);
};

export default Level3Step2;
